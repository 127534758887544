//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// WinningModules
import JuneGameObject from "./JuneGameObject.js";
import JuneSpriteComponent from "./JuneSpriteComponent.js";
import JuneColliderComponent from "./JuneColliderComponent.js";
import BlockComponent from "./BlockComponent.js";
import EmitterComponent from "./EmitterComponent.js";


// App
import constants from "./common/constants.js";
import helper from "./helper.js";


//====================================================================================================
// BlockManager
//====================================================================================================


const BLOCK_TEXTURES = {};
BLOCK_TEXTURES[constants.BLOCK_TYPE_CLOUD] = "cloud.png";
BLOCK_TEXTURES[constants.BLOCK_TYPE_BIG_GUM_1] = "big_gum_1.png";
BLOCK_TEXTURES[constants.BLOCK_TYPE_BIG_GUM_2] = "big_gum_2.png";
BLOCK_TEXTURES[constants.BLOCK_TYPE_BIG_GUM_3] = "big_gum_3.png";
BLOCK_TEXTURES[constants.BLOCK_TYPE_BIG_GUM_4] = "big_gum_4.png";
BLOCK_TEXTURES[constants.BLOCK_TYPE_BIG_GUM_5] = "big_gum_5.png";
BLOCK_TEXTURES[constants.BLOCK_TYPE_EMITTER] = "emitter_up.png";
BLOCK_TEXTURES[constants.BLOCK_TYPE_INVISIBLE] = "cloud.png";

class BlockManager{

	constructor(sceneController){

		this.sceneController = sceneController;

		this.raycaster = new three.Raycaster();
		this.raycaster.layers.set(constants.LAYER_COLLISION);

		this.NUM_COLUMNS = 17.0;
		this.NUM_ROWS = 21.0;

		// Graphics are 16x16 pixels, scaled up x3
		this.BLOCK_SIZE = 64.0;
		this.HALF_BLOCK_SIZE = this.BLOCK_SIZE * 0.5;
		this.GRID_CENTER = new three.Vector2(0.0, 185.0);
		this.GRID_LEFT_LIMIT = this.NUM_COLUMNS * -0.5;
		this.GRID_RIGHT_LIMIT = this.NUM_COLUMNS * 0.5;
		this.GRID_UPPER_LIMIT = this.NUM_ROWS * 0.5;
		this.GRID_LOWER_LIMIT = this.NUM_ROWS * -0.5;

		this.blockMap = {};
		this.makeBlock = this.makeBlock.bind(this);
		this.makeCloudBumps = this.makeCloudBumps.bind(this);
		this.makeCloudBump = this.makeCloudBump.bind(this);
		this.loadBlocks = this.loadBlocks.bind(this);
		this.clearBlocks = this.clearBlocks.bind(this);

	};

	getBlockCollisionFromRay(origin, direction){

		this.raycaster.set(
			origin,
			direction
		);

		const intersections = this.raycaster.intersectObjects(this.sceneController.scene.children, false);

		while(intersections.length > 0){

			const o = intersections.splice(0, 1)[0];

			if(o.object.userData.gameObject.components.BlockComponent){
				return o;
			}

		}

		return null;

	}

	snap(p){

		const coords = this.getCoordinatesFromPosition(p);

		if(!coords){
			return null;
		}

		return this.getPositionFromGridCoordinates(coords);

	}

	getPositionFromGridCoordinates({column, row}){

		return new three.Vector3(
			column * this.BLOCK_SIZE,
			(row * this.BLOCK_SIZE) + this.GRID_CENTER.y,
			constants.Z_BLOCK
		);

	}

	getCoordinatesFromPosition(p){

		let column = Math.round(p.x / this.BLOCK_SIZE);
		let row = Math.round((p.y - this.GRID_CENTER.y) / this.BLOCK_SIZE);

		// Clamp
		if(
			column > this.GRID_RIGHT_LIMIT ||
			column < this.GRID_LEFT_LIMIT ||
			row > this.GRID_UPPER_LIMIT ||
			row < this.GRID_LOWER_LIMIT
		){
			return null;
		}

		return {column: column, row: row};
	}

	makeBlock(column, row, blockType, direction){

		// Don't make if there is already a block at this position
		if(this.getBlockAtCoordinates({column: column, row: row})){
			console.log(`There is already a block at ${column}, ${row}`);
			return;
		}

		const block = new JuneGameObject(`block-${column}-${row}`, this.sceneController);
		block.addComponent(
			new JuneSpriteComponent({
				gameObject: block,
				size: 64,
				texture: BLOCK_TEXTURES[blockType]
			})
		);
		block.addComponent(
			new JuneColliderComponent({
				gameObject: block,
				size: 64
			})
		);
		block.addComponent(
			new BlockComponent({
				gameObject: block,
				column: column,
				row: row,
				type: blockType
			})
		);
		block.setPosition(
			this.getPositionFromGridCoordinates({column: column, row: row})
		);

		this.sceneController.addGameObject(block);

		// Make emitter block
		if(blockType == constants.BLOCK_TYPE_EMITTER){
			block.addComponent(
				new EmitterComponent({
					gameObject: block,
					direction: direction
				})
			)
		}

		// Invisible block
		if(blockType == constants.BLOCK_TYPE_INVISIBLE){
			block.setVisible(false);
		}

		// Add to list
		this.blockMap[this.getStringFromCoordinates({column: column, row: row})] = block;
		window.blocks = this.blockMap;
		window.writeBlocks = this.writeBlocks;
	}

	makeCloudBumps(){

		const that = this;

		Object.values(this.blockMap).forEach(function(block){

			// Only put bumps on cloud blocks
			if(block.components.BlockComponent.type != constants.BLOCK_TYPE_CLOUD){return;}

			// Check up
			const upBlock = that.getBlockAtCoordinates({
				column: block.components.BlockComponent.column,
				row: block.components.BlockComponent.row + 1
			});
			if(!upBlock || upBlock.components.BlockComponent.type > 0){
			// if(!upBlock){
				const bump = that.makeCloudBump();
				bump.setPosition(
					helper.addVectors(
						block.object3D.position,
						new three.Vector3(0.0, that.BLOCK_SIZE, 0.0)
					)
				);
			}

			// Check down
			const downBlock = that.getBlockAtCoordinates({
				column: block.components.BlockComponent.column,
				row: block.components.BlockComponent.row - 1
			});
			if(!downBlock || downBlock.components.BlockComponent.type > 0){
			// if(!downBlock){
				const bump = that.makeCloudBump();
				bump.setPosition(
					helper.addVectors(
						block.object3D.position,
						new three.Vector3(0.0, -that.BLOCK_SIZE, 0.0)
					)
				);
				bump.setRotation({x: 0.0, y: 0.0, z: Math.PI});
			}

			// Check left
			const leftBlock = that.getBlockAtCoordinates({
				column: block.components.BlockComponent.column - 1,
				row: block.components.BlockComponent.row
			});
			if(!leftBlock || leftBlock.components.BlockComponent.type > 0){
			// if(!leftBlock){
				const bump = that.makeCloudBump();
				bump.setPosition(
					helper.addVectors(
						block.object3D.position,
						new three.Vector3(-that.BLOCK_SIZE, 0.0, 0.0)
					)
				);
				bump.setRotation({x: 0.0, y: 0.0, z: Math.PI * 0.5});
			}

			// Check right
			const rightBlock = that.getBlockAtCoordinates({
				column: block.components.BlockComponent.column + 1,
				row: block.components.BlockComponent.row
			});
			if(!rightBlock || rightBlock.components.BlockComponent.type > 0){
			// if(!rightBlock){
				const bump = that.makeCloudBump();
				bump.setPosition(
					helper.addVectors(
						block.object3D.position,
						new three.Vector3(that.BLOCK_SIZE, 0.0, 0.0)
					)
				);
				bump.setRotation({x: 0.0, y: 0.0, z: Math.PI * -0.5});
			}

		});

	};

	makeCloudBump(){

		const cloudBump = new JuneGameObject(`cloudBump-${helper.uuidgen()}`, this.sceneController);
		cloudBump.addComponent(
			new JuneSpriteComponent({
				gameObject: cloudBump,
				dynamic: true,
				size: 64,
				texture: "cloud_bump.png"
			})
		);
		this.sceneController.addGameObject(cloudBump);

		return cloudBump;
	};

	clearBlocks(){

		const blocks = Object.values(this.sceneController.gameObjects).filter(function(x){
			return x.name.includes("block-")
		});
		blocks.forEach(function(x){
			x.onDelete();
		});
		const cloudBumps = Object.values(this.sceneController.gameObjects).filter(function(x){
			return x.name.includes("cloudBump-")
		});
		cloudBumps.forEach(function(x){
			x.onDelete();
		});

		this.blockMap = {};
		window.blocks = {};

	};

	getEmitters(){

		return Object.values(this.blockMap).filter(function(block){

			return block.components.EmitterComponent;

		});

	};

	getStringFromCoordinates({column, row}){
		return `c:${column}, r:${row}`;
	}

	getBlockAtCoordinates({column, row}){
		return this.blockMap[this.getStringFromCoordinates({column: column, row: row})];
	}

	getBlockAtPosition(position){
		return this.getBlockAtCoordinates(this.getCoordinatesFromPosition(position));
	}

	deleteBlock({column, row}){
		const block = this.getBlockAtCoordinates({column: colomn, row: row});

		if(block){
			delete this.blockMap[this.getStringFromCoordinates({column: column, row: row})];
			block.onDelete();
		}

		window.blocks = this.blockMap;
	}

	getRandomFreeSpace(){

		const column = Math.round(
			helper.randomRange(
				-5,
				5
			)
		);
		const row = Math.round(
			helper.randomRange(
				-5,
				5
			)
		);

		if(this.getBlockAtCoordinates({column: column, row: row})){
			return this.getRandomFreeSpace();
		}

		return {column, row};

	};

	writeBlocks(){

		const blockDataList = [];

		Object.values(window.blocks).forEach(function(block){

			const blockData = {
				c: block.components.BlockComponent.column,
				r: block.components.BlockComponent.row,
				tid: block.components.BlockComponent.type
			};

			if(block.components.EmitterComponent){
				blockData.dir = block.components.EmitterComponent.direction;
			}

			blockDataList.push(blockData);

		});

		const s = JSON.stringify(blockDataList);

		console.log(s);

		// To prevent error message
		window.focus();
		// Remove focus from any focused element
		if (document.activeElement) {
    		document.activeElement.blur();
		}

		// Write to clipboard (must be localhost)
		navigator.clipboard.writeText(s);

	}

	loadBlocks(blockDataList){

		const that = this;

		blockDataList.forEach(function(blockData){
			that.makeBlock(
				blockData.c,
				blockData.r,
				blockData.tid,
				blockData.dir
			);
		});

		this.makeCloudBumps();

	}

};

module.exports = BlockManager;


//====================================================================================================
// EOF
//====================================================================================================