//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// June
import JuneComponent from "./JuneComponent.js";

// App
import constants from "./common/constants.js";
import helper from "./helper.js";


//====================================================================================================
// CrawlerComponent
//====================================================================================================


class CrawlerComponent extends JuneComponent{

	constructor(params){

		super(params);

		this.direction = new three.Vector3(0.0, 1.0, 0.0);

		// Bubble release frequency
		this.releaseBubbleFrequency = params.releaseBubbleFrequency;
		this.blocksTraversed = 0;

		this.onLand = this.onLand.bind(this);
		this.findStartingDirection = this.findStartingDirection.bind(this);
	};

	findStartingDirection(){

		const blockSize = this.gameObject.sceneController.blockManager.BLOCK_SIZE;

		// Start at top, turn left until block found. Move 90 degrees left of that block
		for(let angle = 0.0; angle <= Math.PI * 2.0; angle += Math.PI * 0.5){

			const dir = new three.Vector3()
				.copy(this.direction)
				.applyEuler(new three.Euler(0.0, 0.0, angle));
			const pos = helper.addVectors(
				helper.scaleVector(
					dir,
					blockSize
				),
				this.gameObject.object3D.position
			);
			const block = this.gameObject.sceneController.blockManager.getBlockAtPosition(pos);

			if(block){
				this.direction = dir.applyEuler(new three.Euler(0.0, 0.0, Math.PI * 0.5));
				break;
			}

		}

	}

	onLand(){

		const blockSize = this.gameObject.sceneController.blockManager.BLOCK_SIZE;

		// Release bubble every N blocks traversed
		this.blocksTraversed += 1;
		if(this.blocksTraversed % this.releaseBubbleFrequency == 0){
			const greenBubble = this.gameObject.sceneController.activeMode.makeAdHocEnemy("green_bubble");
			if(greenBubble){
				greenBubble.setPosition(this.gameObject.object3D.position);
			}
		}

		// Start at right, rotate left until open space is found, go there
		for(let angle = Math.PI * -0.5; angle <= Math.PI; angle += Math.PI * 0.5){

			const dir = new three.Vector3()
				.copy(this.direction)
				.applyEuler(new three.Euler(0.0, 0.0, angle));
			const pos = helper.addVectors(
				helper.scaleVector(
					dir,
					blockSize
				),
				this.gameObject.object3D.position
			);
			const block = this.gameObject.sceneController.blockManager.getBlockAtPosition(pos);

			if(!block){
				this.gameObject.components.MoveToPoint.destination = pos;
				this.direction = dir;

				// Rotate graphics
				let newAngle = 0.0;
				if(dir.x == 1){
					newAngle = 0.0;
				}else if(dir.x == -1){
					newAngle = -Math.PI;
				}else if(dir.y == 1){
					newAngle = Math.PI * 0.5;
				}else if(dir.y == -1){
					newAngle = -Math.PI * 0.5;
				}
				this.gameObject.setRotation({
					x: 0,
					y: 0,
					z: newAngle
				});
				break;
			}

		}

	};

	update(){

		// Setup MoveToPoint
		if(!this.gameObject.components.MoveToPoint.destination){

			this.findStartingDirection();

			this.gameObject.components.MoveToPoint.reachedDestinationFunc = this.onLand;

			this.onLand();

		}
	};

};

module.exports = CrawlerComponent;


//====================================================================================================
// EOF
//====================================================================================================