//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// WinningModules
import  JuneComponent from "./JuneComponent.js";

// App
import  helper from "./helper.js";
import  constants from "./common/constants.js";


//====================================================================================================
// JuneColliderComponent
//====================================================================================================

const MOVEMENT_REST_THRESHOLD = 0.1;
const collisionMaterial = new three.MeshBasicMaterial();

class JuneColliderComponent extends JuneComponent{

	constructor(params){

		super(params);

		// Size
		this.size = params.size;
		this.radius = this.size * 0.5;

		// Raycaster
		// Add raycaster to proper collision layer
		this.raycaster = new three.Raycaster();
		this.raycaster.layers.set(constants.LAYER_COLLISION);

		// Velocity
		this.velocity = new three.Vector3(0.0, 0.0, 0.0);

		// Collision mesh
		const geo = new three.BoxBufferGeometry(this.size, this.size, this.size);
		this.collisionMesh = new three.Mesh(geo, collisionMaterial);
		this.gameObject.sceneController.scene.add(this.collisionMesh);
		this.collisionMesh.userData.gameObject = this.gameObject;

		// HACK: Offset slightly so raycaster doesn't hit both faces
		this.collisionMesh.position.set(0.0, 0.0, 0.1);

		// Add mesh to proper collision layer
		// Remove mesh from main (viewable) layer
		this.collisionMesh.layers.set(constants.LAYER_COLLISION);

	};

	checkCollision(origin, direction){

		this.raycaster.set(
			origin,
			direction.clone().normalize()
		);

		const intersections = this.raycaster.intersectObjects(this.gameObject.sceneController.scene.children, false);

		// Iterate
		while(intersections.length > 0){

			// Pop off first intersection object
			const io = intersections.splice(0, 1)[0];

			// Skip self
			if(io.object == this.collisionMesh){
				continue;
			}

			// Skip inactive objects
			if(io.object.userData.gameObject.active == false){
				continue;
			}

			// OK to return here because if closest thing isn't close enough,
			// nothing else will be
			if(io.distance > this.radius + (this.velocity.length() * this.app.dt)){
				return;
			}

			// Trigger collision event for everything within range
			this.gameObject.onTriggerEnter2D({
				gameObject: io.object.userData.gameObject,
				intersectionObject: io
			});

		}

		return null;

	};

	onUpdatePosition(){

		this.collisionMesh.position.copy(this.gameObject.object3D.position);
		this.collisionMesh.position.setZ(0.1);

	};

	moveGameObject(){

		this.gameObject.setPosition(
			helper.addVectors(
				this.gameObject.object3D.position,
				helper.scaleVector(
					this.velocity,
					this.app.dt
				)
			)
		);

	};

	onDelete(){

		this.gameObject.sceneController.scene.remove(this.collisionMesh);
		
	};

	update(){

		// Return early if not moving fast enough
		if(this.velocity.length() < MOVEMENT_REST_THRESHOLD){
			return;
		}

		// Use velocity to see if this collider will hit another one
		this.checkCollision(
			this.gameObject.object3D.position,
			this.velocity
		);

		// Update position _after_ checking for collision.
		// If a collision happened, user code might have updated
		// the velocity or position on its own, in which case
		// this will do pretty much nothing (which is fine)
		this.moveGameObject();
	};

};

module.exports = JuneColliderComponent;


//====================================================================================================
// EOF
//====================================================================================================