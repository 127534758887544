//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// WinningModules
import  JuneSceneController from "./JuneSceneController.js";
import  JuneSpriteController from "./JuneSpriteController.js";
import  JuneSpriteComponent from "./JuneSpriteComponent.js";
import  JuneTextComponent from "./JuneTextComponent.js";
import  JuneGameObject from "./JuneGameObject.js";
import  helper from "./helper.js";

// App
import  constants from "./common/constants.js";
import  Button from "./Button.js";


//====================================================================================================
// TitleSceneController
//====================================================================================================


class TitleSceneController extends JuneSceneController {

	constructor(app){

		super(app);

		// Edit mode

		// Override default app settings
		this.app.renderer.setClearColor(constants.COLOR_DARK_BLUE_SKY);
		this.camera.updateProjectionMatrix();

		// Collision
		this.raycaster = new three.Raycaster();
		this.raycaster.layers.set(constants.LAYER_COLLISION);

		// Bind functions
		this.initialize = this.initialize.bind(this);
		this.onMouseDown = this.onMouseDown.bind(this);

	};


	//====================================================================================================
	// Buttons
	//====================================================================================================


	makeMenuButton({text, position, onPressFunc}){

		const buttonHeight = 64;
		const fontSize = 64;

		// Text sprite
		const textGameObject = new JuneGameObject(helper.uuidgen(), this);
		textGameObject.addComponent(
			new JuneTextComponent({
				gameObject: textGameObject,
				text: text,
				size: fontSize
			})
		);
		textGameObject.setPosition(position);
		this.addGameObject(textGameObject);

		// Button
		let button = new Button({
			position: position,
			collisionSize: new three.Vector2(
				this.app.gameWidth,
				buttonHeight
			),
			onHoverStartFunc: function(){
			},
			onHoverEndFunc: function(){
			},
			onPressFunc: function(){
				onPressFunc();
			},
			onDownFunc: function(){
			},
			onReleaseFunc: function(){
			},
			onCancelFunc: function(){
			},
			z: 1.0
		});
		this.buttons.push(button);

	};


	// Arrange buttons
	async initialize(){

		const that = this;

		// Setup sprite controller
		this.spriteController = new JuneSpriteController({
			sceneController: this,
			spritePoolSize: 32
		});
		this.scene.add(this.spriteController.masterMesh);

		// Logo sprite
		const logoGameObject = new JuneGameObject(helper.uuidgen(), this);
		logoGameObject.addComponent(
			new JuneSpriteComponent({
				gameObject: logoGameObject,
				size: 1024,
				texture: "logo.png"
			})
		);
		logoGameObject.setPosition({
			x: 0.0,
			y: this.app.gameHeight * 0.25,
			z: 0.0
		});
		this.addGameObject(logoGameObject);

		this.makeMenuButton({
			text: "PLAY",
			position: {x: 0.0, y: 0.0, z: 0.0},
			onPressFunc: that.app.gotoGameFromTitle
		});

		// this.makeMenuButton({
		// 	text: "OPTIONS",
		// 	position: {x: 0.0, y: -100.0, z: 0.0},
		// });

	};


	//====================================================================================================
	// Mouse
	//====================================================================================================


	onMouseDown(e){
	};

	onTouchEnd(e){
	};


	//====================================================================================================
	// Update loop
	//====================================================================================================


	update(){};

};

module.exports = TitleSceneController;


//====================================================================================================
// EOF
//====================================================================================================