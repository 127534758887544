//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// Modules
import  constants from "./common/constants.js";
import  JuneAudioController from "./JuneAudioController.js";
import  helper from "./helper.js";


//====================================================================================================
// JuneApp
//====================================================================================================


class JuneApp{

	constructor({gameWidth, gameHeight, supportedOrientations}){

		this.frameNumber = 0;

		// sceneControllers
		this.sceneControllers = {};
		this.activeSceneController = null;

		// UI
		this.ongoingTouches = [];
		this.mouseScreenPosition = new three.Vector2(0.0, 0.0);
		this.mouseWorldPosition = new three.Vector2(0.0, 0.0);
		this.lastMouseWorldPosition = new three.Vector2(0.0, 0.0);
		this.lastMouseScreenPosition = new three.Vector2(0.0, 0.0);
		this.mouseDownLastFrame = false;
		this.mouseIsDown = false;
		this.mousePressedThisFrame = false;
		this.mouseReleasedThisFrame = false;

		// Dimensions
		this.supportedOrientations = supportedOrientations;
		this.devicePixelRatio = window.devicePixelRatio;
		this.gameWidth = gameWidth;
		this.gameHeight = gameHeight;
		this.aspectRatio = this.gameWidth / this.gameHeight;
		this.focus = true;

		// Renderer
		this.renderer = null;
		this.raycaster = new three.Raycaster();

		// Time
		this.clock = new three.Clock();
		this.dt = this.clock.getDelta();

		// Bind
		this.onTouchStart = this.onTouchStart.bind(this);
		this.onTouchMove = this.onTouchMove.bind(this);
		this.onTouchEnd = this.onTouchEnd.bind(this);
		this.onMouseDown = this.onMouseDown.bind(this);
		this.onMouseMove = this.onMouseMove.bind(this);
		this.onMouseUp = this.onMouseUp.bind(this);
		this.preventGestures = this.preventGestures.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.onKeyUp = this.onKeyUp.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onFocus = this.onFocus.bind(this);

	}

	initializeRenderer(){

		// Device aspect ratio
		let deviceAspectRatio = window.innerWidth / window.innerHeight;

		if(deviceAspectRatio < this.aspectRatio){
			console.log("Device aspect ratio is taller, make canvas stick to width");
			this.canvasWidth = window.innerWidth;
			this.canvasHeight = this.canvasWidth / this.aspectRatio;
		}else{
			console.log("Device aspect ratio is shorter, make canvas stick to height");
			this.canvasHeight = window.innerHeight;
			this.canvasWidth = this.canvasHeight * this.aspectRatio;
		}

		// Alert if orientation isn't supported
		if(!this.supportedOrientations.includes(constants.ORIENTATION_PORTRAIT) && window.innerHeight > window.innerWidth){
			alert("This game is best played in landscape orientation");
		}
		if(!this.supportedOrientations.includes(constants.ORIENTATION_LANDSCAPE) && window.innerHeight < window.innerWidth){
			alert("This game is best played in portrait orientation");
		}

		// Canvas
		this.renderer = new three.WebGLRenderer();
		this.renderer.setPixelRatio(window.devicePixelRatio);
		this.renderer.setClearColor(0x000000);

		// Use "updateStyle: true" to change canvas element width/height based on device screen size.
		this.renderer.setSize(this.canvasWidth, this.canvasHeight, true);

		// Use "updateStyle: false" to change viewport dimensions back to game dimensions,
		// but leave canvas element size as it was (dynamic based on device screen).
		// This lets us use static game world units for game object sizes and positions
		// instead of needing to manually scale everything based on dynamic canvas sizes.
		this.renderer.setSize(this.gameWidth, this.gameHeight, false);
		this.renderer.domElement.id = "canvas";
		this.renderer.domElement.style.margin = "auto";
		this.renderer.domElement.style.display = "block";
		document.body.prepend(this.renderer.domElement);

		this.rendererTop = this.renderer.domElement.getBoundingClientRect().top;
		this.rendererLeft = this.renderer.domElement.getBoundingClientRect().left;

	}


	//====================================================================================================
	// UI controls
	//====================================================================================================


	addEventListeners(){

		// Touch
		document.addEventListener("touchstart", this.onTouchStart, {passive: false});
		document.addEventListener("touchmove", this.onTouchMove, {passive: false});
		document.addEventListener("touchend", this.onTouchEnd, {passive: false});
		document.addEventListener("touchcancel", this.onTouchCancel, {passive: false});

		// Mouse
		document.addEventListener("mousedown", this.onMouseDown, {passive: false});
		document.addEventListener("mousemove", this.onMouseMove, {passive: false});
		document.addEventListener("mouseup", this.onMouseUp, {passive: false});

		// Special hack to prevent zoom-to-tabs gesture in safari
		document.addEventListener("gesturestart", this.preventGestures, {passive: false});
		document.addEventListener("gesturechange", this.preventGestures, {passive: false});
		document.addEventListener("gestureend", this.preventGestures, {passive: false});

		// Keyboard
		document.addEventListener("keydown", this.onKeyDown, {passive: false});
		document.addEventListener("keyup", this.onKeyUp, {passive: false});

		// Window focus
		window.addEventListener("blur", this.onBlur);
		window.addEventListener("focus", this.onFocus);

	};

	onBlur(){
		this.focus = false;
		this.clock.stop();
	};

	onFocus(){
		this.focus = true;
		this.clock.start();
	};

	onKeyDown(e){

		if(this.activeSceneController.keyInputs[e.code]){
			this.activeSceneController.keyInputs[e.code].down = true;
			this.activeSceneController.keyInputs[e.code].shiftKey = e.shiftKey;
		}

	};

	onKeyUp(e){

		if(this.activeSceneController.keyInputs[e.code]){
			this.activeSceneController.keyInputs[e.code].down = false;
		}

	}

	preventGestures(e){
		e.preventDefault();
	};

	copyTouch({ identifier, pageX, pageY }){
		return { identifier, pageX, pageY };
	};

	ongoingTouchIndexById(id){

		for(let i = 0; i < this.ongoingTouches.length; i++){
		  
			if(id == this.ongoingTouches[i].identifier){
				return i;
			}

		}

		return -1;  // not found

	};

	onMouseDown(e){

		const touch = this.copyTouch({identifier: "mouse", pageX: e.pageX, pageY: e.pageY, downThisFrame: true})
		touch.startedThisFrame = true;
		this.ongoingTouches.push(touch);

	}

	onMouseMove(e){

		// Only worry about move moving if button is down.
		if(e.buttons === 0){
			return;
		}

		const index = this.ongoingTouchIndexById("mouse");

		// Update our array for the touch that moved
		this.ongoingTouches.splice(
			index,
			1,
			this.copyTouch({identifier: "mouse", pageX: e.pageX, pageY: e.pageY})
		);

	}

	onMouseUp(e){

		const index = this.ongoingTouchIndexById("mouse");
		this.ongoingTouches.splice(index, 1);  // remove it; we're done

		if(this.activeSceneController.onTouchEnd){
			this.activeSceneController.onTouchEnd();
		}

	}

	onTouchStart(e){

		e.preventDefault();

		const touches = e.changedTouches;
        
		for(let i = 0; i < touches.length; i++){
			const touch = this.copyTouch(touches[i]);
			touch.startedThisFrame = true;
			this.ongoingTouches.push(touch);
		}

		return false;

	};

	onTouchMove(e){

		e.preventDefault();

		const touches = e.changedTouches;
        
		for(let i = 0; i < touches.length; i++){

			const index = this.ongoingTouchIndexById(touches[i].identifier);

			// Update our array for the touch that moved
			this.ongoingTouches.splice(index, 1, this.copyTouch(touches[i]));

		}

		return false;

	};

	onTouchCancel(e){

		const touches = e.changedTouches;

		for(let i = 0; i < touches.length; i++){

			const index = this.ongoingTouchIndexById(touches[i].identifier);
			this.ongoingTouches.splice(index, 1);  // remove it; we're done

		}

		if(this.activeSceneController.onTouchEnd){
			this.activeSceneController.onTouchEnd();
		}

	};

	onTouchEnd(e){

		const touches = e.changedTouches;

		for(let i = 0; i < touches.length; i++){

			const index = this.ongoingTouchIndexById(touches[i].identifier);
			this.ongoingTouches.splice(index, 1);  // remove it; we're done

		}

		if(this.activeSceneController.onTouchEnd){
			this.activeSceneController.onTouchEnd();
		}

	};

	worldToScreenCoordinates({x, y}){

		const fromTopLeft = helper.subVectors(
			{x: -this.gameWidth * 0.5, y: this.gameHeight * 0.5},
			{x, y}
		);

		const scaledTopLeft = {
			x: Math.abs(fromTopLeft.x / this.gameWidth),
			y: Math.abs(fromTopLeft.y / this.gameHeight),
		}

		const canvasTopLeft = {
			x: scaledTopLeft.x * this.canvasWidth,
			y: scaledTopLeft.y * this.canvasHeight
		};

		return {
			x: canvasTopLeft.x + this.rendererLeft,
			y: canvasTopLeft.y + this.rendererTop
		};

	};

	screenToWorldCoordinates({x, y}){

		var offsetX = x - this.rendererLeft;
		var offsetY = y - this.rendererTop;

		var scaledX = ((offsetX / this.canvasWidth) * 2.0) - 1.0;
		var scaledY = (-(offsetY / this.canvasHeight) * 2.0) + 1.0;

		this.raycaster.setFromCamera(
			new three.Vector2(
				scaledX,
				scaledY
			),
			this.activeSceneController.camera
		);

		return this.raycaster.ray.origin;

	};

	checkButtons(){

		// Reset buttons
		var activeButtonList = this.activeSceneController.buttons;
		for(var b in activeButtonList){
				var button = activeButtonList[b];
				button.isDownThisFrame = false;
		}

		// Check if touches are over buttons
		for(let t = 0; t < this.ongoingTouches.length; t++){

			const touch = this.ongoingTouches[t];
			// Get screen position
			const touchScreenPosition = {
				x: touch.pageX,
				y: touch.pageY
			};

			const touchWorldPosition = this.screenToWorldCoordinates(touchScreenPosition);

			// Scene level touch handler
			if(this.activeSceneController.onMouseDown){
				this.activeSceneController.onMouseDown(touchWorldPosition);
			}

			for(var b in activeButtonList){

				var button = activeButtonList[b];

				if(button.collisionBox.containsPoint(touchWorldPosition)){

					// Check if just pressed, or already down
					if(!touch.startedThisFrame && !button.wasDownLastFrame){
						button.isDownThisFrame = true;
						button.onHoverStartFunc(touchWorldPosition);
					}else if(touch.startedThisFrame){
						button.isDownThisFrame = true;
						button.onPressFunc(touchWorldPosition);
					}else{
						button.isDownThisFrame = true;
						button.onDownFunc(touchWorldPosition);
					}

				}

			}

			// Reset touch states
			touch.startedThisFrame = false;

		}

		// Check if button were released
		for(var b in activeButtonList){
			var button = activeButtonList[b];

			if(button.wasDownLastFrame && !button.isDownThisFrame){
				button.onReleaseFunc();
			}

		}

		// Persist state
		for(var b in activeButtonList){
			var button = activeButtonList[b];
			button.wasDownLastFrame = button.isDownThisFrame;
		}

	};

	checkKeyboard(){

		const downKeys = Object.values(this.activeSceneController.keyInputs).forEach(function(k){

			if(k.down === true){
				k.onDownFunc(k.shiftKey);
			}

		});

	}


	//====================================================================================================
	// Update loop
	//====================================================================================================


	update(){

		// Skip if browser tab not in focus
		if(!this.focus){
			return;
		}

		this.frameNumber += 1;

		// Store delta time
		this.dt = this.clock.getDelta();

		// Check inputs
		this.checkButtons();
		this.checkKeyboard();

		// Run update function for activeSceneController
		this.activeSceneController.update();

	};

};

module.exports = JuneApp;


//====================================================================================================
// EOF
//====================================================================================================