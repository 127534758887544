//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// WinningModules
import  JuneComponent from "./JuneComponent.js";

// App
import  helper from "./helper.js";
import  constants from "./common/constants.js";


//====================================================================================================
// ParticleComponent
//====================================================================================================


class ParticleComponent extends JuneComponent{

	constructor(params){

		super(params);

		// Velocity
		this.velocity = new three.Vector3(0.0, 0.0, 0.0);
		this.friction = params.friction || 1.0;

		// TTL
		this.ttl = params.ttl;

		// Pooling
		this.gameObject.active = false;
		this.next = null;

	};

	activate(){
		this.gameObject.active = true;
		this.gameObject.setVisible(true);
	}

	deactivate(){
		this.gameObject.active = false;
		this.gameObject.setVisible(false);
		this.gameObject.sceneController.setFreeParticle(this.gameObject);
	}

	update(){

		// Reduce velocity
		const newVel =helper.scaleVector(
			this.velocity,
			this.friction
		);
		this.velocity.set(newVel.x, newVel.y, newVel.z);

		// Update position
		this.gameObject.setPosition(
			helper.addVectors(
				this.gameObject.object3D.position,
				helper.scaleVector(
					this.velocity,
					this.app.dt
				)
			)
		);

		// Decrement ttl
		this.ttl -= this.app.dt;

		if(this.ttl <= 0){
			this.deactivate();
		}

	};

};

module.exports = ParticleComponent;


//====================================================================================================
// EOF
//====================================================================================================