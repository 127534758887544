//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// June
import JuneComponent from "./JuneComponent.js";

// App
import constants from "./common/constants.js";
import helper from "./helper.js";


//====================================================================================================
// BubbleComponent
//====================================================================================================


const pinkBubbles = [
	"bubble_small.png",
	"bubble_medium.png",
	"bubble_large.png"
];

const purpleBubbles = [
	"bubble_small_purple.png",
	"bubble_medium_purple.png",
	"bubble_large_purple.png"
];


class BubbleComponent extends JuneComponent{

	constructor(params){

		super(params);
		
		this.onCollect = this.onCollect.bind(this);

		// Choose color and texture
		this.color = Math.floor(Math.random() * 2);

		let texture = "";
		const randNumber = Math.floor(Math.random() * pinkBubbles.length);

		console.log(this);
		console.log(this.gameObject.components);

		if(this.color == 0){
			texture = pinkBubbles[randNumber];
		}else{
			texture = purpleBubbles[randNumber];
		}
		this.gameObject.components.JuneSpriteComponent.updateUvsForTextureKey(texture);

		// Pooling
		this.gameObject.active = false;
		this.next = null;

	};

	onTriggerEnter2D({gameObject, intersectionObject}){

		// Hit player
		if(gameObject.components.PlayerComponent){
			this.onCollect();
		}

	};

	onCollect(){

		// Report back to Mode
		this.gameObject.sceneController.activeMode.onCollect();

		// Play SFX
		this.gameObject.sceneController.app.audioController.play("bubble_collect.wav");

		// Make particles
		this.makeParticles();

		// Remove object
		this.deactivate();

	};

	makeParticles(){

		const numParticles = 16;
		const step = (Math.PI * 2.0) / numParticles;
		const radius = 24;
		const maxSpeed = 60.0;

		for(let i = 0; i < numParticles; i++){

			const particle = this.gameObject.sceneController.getFreeParticle();

			// It's possible that no particle is returned because too many are already active
			if(particle){
				particle.components.ParticleComponent.activate();
				particle.components.ParticleComponent.ttl = 1.0;
				particle.components.ParticleComponent.friction = 0.95;

				// Set position and rotation
				const rads = i * step;
				const direction = {
					x: Math.cos(rads),
					y: Math.sin(rads),
					z: 0.0
				};

				particle.setPosition(
					helper.addVectors(
						this.gameObject.object3D.position,
						helper.scaleVector(
							direction,
							radius
						)
					)
				);
				const newVel = helper.scaleVector(
					direction,
					helper.randomRange(maxSpeed * 0.5, maxSpeed)
				);

				particle.components.ParticleComponent.velocity.set(
					newVel.x, newVel.y, newVel.z
				);


				// Update particle color
				if(this.color == 0){
					particle.components.JuneSpriteComponent.updateUvsForTextureKey("particle_pink.png");
				}else{
					particle.components.JuneSpriteComponent.updateUvsForTextureKey("particle_purple.png");
				}

			}
		}

	}

	activate(){
		this.gameObject.active = true;
		this.gameObject.setVisible(true);
	}

	deactivate(){
		this.gameObject.active = false;
		this.gameObject.setVisible(false);
		this.gameObject.sceneController.setFreeBubble(this.gameObject);
	}

	onDelete(){
	}

};

module.exports = BubbleComponent;


//====================================================================================================
// EOF
//====================================================================================================