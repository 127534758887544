//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// WinningModules
import  JuneGameObject from "./JuneGameObject.js";
import  PlayerBulletComponent from "./PlayerBulletComponent.js";
import  JuneComponent from "./JuneComponent.js";
import  JuneColliderComponent from "./JuneColliderComponent.js";
import  JuneSpriteComponent from "./JuneSpriteComponent.js";

// App
import  constants from "./common/constants.js";
import  MoveToPoint from "./MoveToPoint.js";
import  helper from "./helper.js";


//====================================================================================================
// PlayerComponent
//====================================================================================================


class PlayerComponent extends JuneComponent{

	constructor(params){

		super(params);

		this.blockManager = this.gameObject.sceneController.blockManager;

		// Jumping and landing
		this.slidePosition = {};
		this.isJumping = false;

		// Shooting
		this.reloadTimer = -1;
		this.reloadDuration = 0.16;
		this.shotSpeed = 600.0;

		// Shake
		this.shakeTimer = -1;
		this.shakeDuration = 0.16;

		this.land = this.land.bind(this);
		this.shoot = this.shoot.bind(this);
		this.jump = this.jump.bind(this);
		this.onKeyboardRotate = this.onKeyboardRotate.bind(this);
		this.onDamage = this.onDamage.bind(this);
		this.onDefeat = this.onDefeat.bind(this);

	};

	shoot(){

		// Handle reloadTimer
		if(this.reloadTimer > 0){
			return;
		}

		this.reloadTimer = this.reloadDuration;

		// Make bullet
		const direction = new three.Vector3(0.0, 1.0, 0.0)
			.applyEuler(this.gameObject.object3D.rotation)

		const velocity = helper.addVectors(
			this.gameObject.components.JuneColliderComponent.velocity,
			helper.scaleVector(
				direction,
				this.shotSpeed
			)
		);

		const position = this.gameObject.object3D.position.clone()
			.addScaledVector(direction, 10.0);

		const bullet = new JuneGameObject(
			`bullet-${helper.uuidgen()}`,
			this.gameObject.sceneController
		);
		bullet.addComponent(
			new JuneColliderComponent({
				gameObject: bullet,
				size: 64
			})
		);
		bullet.addComponent(
			new JuneSpriteComponent({
				gameObject: bullet,
				dynamic: true,
				size: 64,
				texture: "shooting_star.png"
			})
		);
		bullet.addComponent(
			new PlayerBulletComponent({
				gameObject: bullet,
				velocity: velocity
			})
		);
		
		bullet.setPosition(position);
		bullet.setRotation(this.gameObject.object3D.rotation);
		this.gameObject.sceneController.addGameObject(bullet);

		// SFX
		this.gameObject.sceneController.app.audioController.play("bubble_shoot.wav");
	}

	land(){

		this.isJumping = false;
		this.gameObject.components.MoveToPoint.destination = null;

		this.gameObject.components.DottedLineComponent.drawDottedLine(
			this.gameObject.object3D.position,
			this.gameObject.object3D.rotation.z,
			this.LINE_LENGTH,
			0,
			0
		);

		// Inform game mode
		if(this.gameObject.sceneController.activeMode.onLand){

			this.gameObject.sceneController.activeMode.onLand();

		}

	};

	jump(){

		const target = this.gameObject.components.DottedLineComponent.lineTarget;

		if(!target){return;}

		if(this.isJumping === true){return;}

		// Set rotation
		const normal = target.face.normal;

		this.isJumping = true;
		this.gameObject.components.MoveToPoint.destination = target.object.userData.gameObject.object3D.position.clone()
																.addScaledVector(normal, this.blockManager.BLOCK_SIZE);
															

		// Reset dottedLine on landing
		this.gameObject.components.MoveToPoint.reachedDestinationFunc = this.land;

		this.gameObject.components.DottedLineComponent.clearAll();

		// Play jump SFX
		this.gameObject.sceneController.app.audioController.play("jump.wav");

	};

	onDirectionSlide(touchWorldPosition){

		const buttonCenterPosition = {
			x: -this.gameObject.sceneController.app.gameWidth * 0.25, 
			y: (this.gameObject.sceneController.app.gameHeight * -0.5) + (this.gameObject.sceneController.app.gameWidth * 0.25),
			z: 0.0
		};

		let diff = helper.subVectors(
			touchWorldPosition,
			buttonCenterPosition
		);

		let angle = Math.atan2(diff.y, diff.x) - 1.57;

		this.gameObject.setRotation({
			x: 0.0,
			y: 0.0,
			z: angle
		});

		// If jumping, just rotate ship and don't draw line
		if(!this.isJumping){

			this.gameObject.components.DottedLineComponent.drawDottedLine(
				this.gameObject.object3D.position,
				this.gameObject.object3D.rotation.z,
				this.LINE_LENGTH,
				0,
				0
			);

		}

		this.gameObject.sceneController.slidePosition = touchWorldPosition.x;

	};

	onSlide(touchWorldPosition){

		let diff = touchWorldPosition.x - this.gameObject.sceneController.slidePosition;
		const factor = 0.005;
		let angleChange = diff * factor;
		this.gameObject.setRotation({
			x: 0.0,
			y: 0.0,
			z: this.gameObject.object3D.rotation.z - angleChange
		});

		// If jumping, just rotate ship and don't draw line
		if(!this.isJumping){

			this.gameObject.components.DottedLineComponent.drawDottedLine(
				this.gameObject.object3D.position,
				this.gameObject.object3D.rotation.z,
				this.LINE_LENGTH,
				0,
				0
			);

		}

		this.gameObject.sceneController.slidePosition = touchWorldPosition.x;

	};

	onKeyboardRotate(direction){

		const factor = 0.1;

		this.gameObject.setRotation({
			x: 0.0,
			y: 0.0,
			z: this.gameObject.object3D.rotation.z - (factor * direction)
		});

		// If jumping, just rotate ship and don't draw line
		if(!this.isJumping){

			this.gameObject.components.DottedLineComponent.drawDottedLine(
				this.gameObject.object3D.position,
				this.gameObject.object3D.rotation.z,
				this.LINE_LENGTH,
				0,
				0
			);

		}

	};

	onTriggerEnter2D({gameObject, intersectionObject}){

		// Collect bubbles
		if(gameObject.components.BubbleComponent){
			gameObject.components.BubbleComponent.onCollect();
		}

		// Hit enemies
		if(gameObject.components.EnemyComponent){
			this.gameObject.components.DamageTakerComponent.damage(1.0);
			// this.gameObject.sceneController.startGameOver();
		}

		// Bounce bubbles
		if(gameObject.components.StarItemComponent){
			gameObject.components.StarItemComponent.onCollect();
		}

	};

	onDamage(){

		const heartString = `hearts_${Math.trunc(this.gameObject.components.DamageTakerComponent.hp)}.png`;
		this.gameObject.sceneController.leftIcon.components.JuneSpriteComponent.updateUvsForTextureKey(heartString);

		this.shakeTimer = this.shakeDuration;

	};

	shake(){

		this.shakeTimer -= this.app.dt;

		const shakeRange = 5.0;
		const shakeRotationRange = 0.25;
		const randShakeX = helper.randomRange(-shakeRange, shakeRange);
		const randShakeY = helper.randomRange(-shakeRange, shakeRange);
		const randShakeRotation = helper.randomRange(-shakeRotationRange, shakeRotationRange);

		this.gameObject.setPosition({
			x: this.gameObject.object3D.position.x + randShakeX,
			y: this.gameObject.object3D.position.y + randShakeY,
			z: this.gameObject.object3D.position.z
		});
		this.gameObject.setRotation({
			x: this.gameObject.object3D.rotation.x,
			y: this.gameObject.object3D.rotation.y,
			z: this.gameObject.object3D.rotation.z + randShakeRotation
		});

	}

	onDefeat(){

		// Inform mode
		this.gameObject.sceneController.startGameOver();


	};

	restart(){

		this.isJumping = false;
		this.gameObject.components.MoveToPoint.destination = null;
		this.gameObject.components.JuneColliderComponent.velocity.set(0.0, 0.0, 0.0);
		
	};

	update(){

		if(this.reloadTimer >= 0){
			this.reloadTimer -= this.app.dt;
		}

		// Shake
		if(this.shakeTimer >= 0){
			this.shake();
		}

	}

};

module.exports = PlayerComponent;


//====================================================================================================
// EOF
//====================================================================================================