//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// June
import JuneComponent from "./JuneComponent.js";

// App
import constants from "./common/constants.js";
import helper from "./helper.js";


//====================================================================================================
// DamageTakerComponent
//====================================================================================================


class DamageTakerComponent extends JuneComponent{

	constructor(params){

		super(params);

		this.hp = params.hp;

		// Invincible duration
		this.invincibleDuration = params.invincibleDuration;
		this.invincibleTimer = -1;

		// Flash
		this.flashDuration = 0.1;
		this.flashTimer = -1;

		this.normalTexture = params.normalTexture;
		this.flashTexture = params.flashTexture;

		this.onDefeatFunc = params.onDefeatFunc;

		this.onDamageCallback = params.onDamageCallback;
		
	};

	damage(hp){

		// Don't damage if temporarily invincible
		if(this.invincibleTimer > 0){
			return;
		}

		// Restart timer on damage
		this.invincibleTimer = this.invincibleDuration;

		// Make sprite flash
		this.gameObject.components.JuneSpriteComponent.updateUvsForTextureKey(this.flashTexture);
		this.flashTimer = this.flashDuration;

		// Take hp
		this.hp -= hp;

		if(this.onDamageCallback){
			this.onDamageCallback();
		}

		if(this.hp <= 0){
			if(this.onDefeatFunc){
				this.onDefeatFunc();
			}
		}


	};

	update(){

		if(this.invincibleTimer >= 0){
			this.invincibleTimer -= this.app.dt;
		}

		if(this.flashTimer >= 0){
			this.flashTimer -= this.app.dt;

			// Return to normal
			if(this.flashTimer <= 0){
				this.gameObject.components.JuneSpriteComponent.updateUvsForTextureKey(this.normalTexture);
			}
		}

	};

};

module.exports = DamageTakerComponent;


//====================================================================================================
// EOF
//====================================================================================================