//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// June Modules
import  JuneGameObject from "./JuneGameObject.js";
import  JuneColliderComponent from "./JuneColliderComponent.js";
import  JuneSceneController from "./JuneSceneController.js";
import  JuneSpriteComponent from "./JuneSpriteComponent.js";
import  JuneSpriteController from "./JuneSpriteController.js";
import  Button from "./Button.js";

// App
import  constants from "./common/constants.js";
import  helper from "./helper.js";

import  PlayerComponent from "./PlayerComponent.js";
import  BlockManager from "./BlockManager.js";

// Modes
import  ModeBubbleRace from "./ModeBubbleRace.js";
import  ModeZoneOut from "./ModeZoneOut.js";
import  ModeProgression from "./ModeProgression.js";
import  MoveToPoint from "./MoveToPoint.js";
import  EnemyComponent from "./EnemyComponent.js";
import  BubbleComponent from "./BubbleComponent.js";
import  ParticleComponent from "./ParticleComponent.js";
import  StarComponent from "./StarComponent.js";
import  StarItemComponent from "./StarItemComponent.js";
import  FloatComponent from "./FloatComponent.js";
import  JuneTextComponent from "./JuneTextComponent.js";
import  EnemyFlyerComponent from "./EnemyFlyerComponent.js";
import  EnemyMiirComponent from "./EnemyMiirComponent.js";
import  DamageTakerComponent from "./DamageTakerComponent.js";
import  CrawlerComponent from "./CrawlerComponent.js";
import  DottedLineComponent from "./DottedLineComponent.js";


//====================================================================================================
// ResultSceneController
//====================================================================================================



class SplashSceneController extends JuneSceneController{

	constructor(app){

		super(app);

		// Override default app settings
		this.app.renderer.setClearColor(constants.COLOR_DARK_BLUE_SKY);
		this.camera.updateProjectionMatrix();

		// Collision
		this.raycaster = new three.Raycaster();
		this.raycaster.layers.set(constants.LAYER_COLLISION);

		// State

		// Bind functions
		this.initialize = this.initialize.bind(this);

	};


	//====================================================================================================
	// Initialization
	//====================================================================================================


	async initialize(){
		
		const that = this;

		// Setup sprite controller
		this.spriteController = new JuneSpriteController({
			sceneController: this,
			spritePoolSize: 32
		});
		this.scene.add(this.spriteController.masterMesh);

		// Tap to play
		const tapToPlay = new JuneGameObject(helper.uuidgen(), this);
		tapToPlay.addComponent(
			new JuneTextComponent({
				gameObject: tapToPlay,
				text: "Tap To Start!",
				size: 32
			})
		);
		tapToPlay.setPosition({
			x: 0.0,
			y: 0.0,
			z: 0.0
		});
		this.addGameObject(tapToPlay);

	}


	//====================================================================================================
	// CLEAN UP
	//====================================================================================================


	cleanUp(){

		while(this.buttons.length > 0){

			let button = this.buttons.pop();

		}

	};
	
	//====================================================================================================
	// UI
	//====================================================================================================


	onTouchEnd(e){
		this.app.gotoTitleFromSplash(e);
	};


	//====================================================================================================
	// STATE
	//====================================================================================================




	//====================================================================================================
	// Update loop
	//====================================================================================================


	update(){

		// Update based on game state
		switch(this.state){

		
		}

	};

};

module.exports = SplashSceneController;


//====================================================================================================
// EOF
//====================================================================================================