//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";


//====================================================================================================
// JuneSpriteController
//====================================================================================================


class JuneSpriteController{

	constructor(params){

		this.sceneController = params.sceneController;
		this.planeGeometry = new three.PlaneGeometry(1.0, 1.0);
		this.masterGeometry = new three.Geometry();
		this.masterMesh = new three.Mesh(this.masterGeometry, this.sceneController.app.textureAtlas.masterMaterial);

		// Create pool of pre-constructed sprite references
		// Reference is:
		// {
		// 	faceIndex: int
		// 	vertexIndex: int
		// 	active: bool
		// 	next: reference
		// }
		this.spritePoolSize = params.spritePoolSize;
		this.spritePool = [];
		this.firstAvailableSprite = null;
		this.createSpritePool();
	};

	createSpritePool(){

		for(let i = 0; i < this.spritePoolSize; i++){

			const sprite = new three.Mesh(this.planeGeometry, this.masterMaterial);
			this.masterMesh.geometry.mergeMesh(sprite);
	
			// Store last 4 vertices and last 2 faces
			this.spritePool.push({
				faceIndex: this.masterMesh.geometry.faces.length - 2,
				vertexIndex: this.masterMesh.geometry.vertices.length - 4,
				active: false,
				next: null
			});

		}

		// Set next
		for(let i = 0; i < this.spritePoolSize - 1; i++){
			this.spritePool[i].next = this.spritePool[i+1];
		}
		this.spritePool[this.spritePoolSize - 1].next = null;
		
		// Set firstAvailable
		this.firstAvailableSprite = this.spritePool[0];
	};

	getFreeSprite(){

		if(this.firstAvailableSprite === null){
			throw(new Error("Out of sprites"));
		}

		const returnSprite = this.firstAvailableSprite;
		this.firstAvailableSprite = returnSprite.next;

		return returnSprite;
	};

	setFreeSprite(sprite){
		sprite.next = this.firstAvailableSprite;
		this.firstAvailableSprite = sprite;
	};

};

module.exports = JuneSpriteController;


//====================================================================================================
// EOF
//====================================================================================================