//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// WinningModules

// App
import  BubbleComponent from "./BubbleComponent.js";
import  constants from "./common/constants.js";
import  JuneColliderComponent from "./JuneColliderComponent.js";
import  JuneGameObject from "./JuneGameObject.js";
import  JuneSpriteComponent from "./JuneSpriteComponent.js";
import  helper from "./helper.js";


//====================================================================================================
// ModeProgression
//====================================================================================================


class ModeProgression{

	constructor(sceneController){

		this.sceneController = sceneController;

		this.maxBubbles = 16;
		this.maxEnemies = 16;

		// Emitters
		this.emitters = this.sceneController.blockManager.getEmitters();
		this.emitterIndex = 0;

		this.emitBubble = this.emitBubble.bind(this);
		this.emitMaxBubbles = this.emitMaxBubbles.bind(this);
		this.onCollect = this.onCollect.bind(this);
		this.incrementCollectedBubbleCount = this.incrementCollectedBubbleCount.bind(this);

		// States
		this.bubblePhaseDuration = 20.0;
		this.bubblePhaseEndWarningDuration = 5.0;
		this.state = "bubblePhase";
		this.timer = this.bubblePhaseDuration;
		this.numEnemiesInPlay = 0;
		this.numBubblesInPlay = 0;
		this.comboBubbles = 0;
		this.numBubblesCollected = 0;
		this.waves = null;
		this.waveIndex = -1;

	};

	initialize(waves){

		// Load events
		this.waves = waves;

		this.emitMaxBubbles();

	};

	emitBubble(){
		
		// Choose one
		const randNum = Math.floor(Math.random() * this.emitters.length);

		// Emit from that one
		this.emitters[randNum].components.EmitterComponent.emit();

		// Increment
		this.numBubblesInPlay += 1;

	};

	emitMaxBubbles(){

		const diff = this.maxBubbles - this.numBubblesInPlay;

		for(let i = 0; i < diff; i++){
			this.emitBubble();
		}

	};

	emitGameObject(gameObject){

		// Choose emitter to use
		const emitter = this.emitters[this.emitterIndex];
		this.emitterIndex += 1;
		if(this.emitterIndex >= this.emitters.length){
			this.emitterIndex = 0;
		}

		const coordinates = this.sceneController.blockManager.getCoordinatesFromPosition(emitter.object3D.position);
		switch(emitter.components.EmitterComponent.direction){
			case 0:
				coordinates.row += 1;
				break;
			case 1:
				coordinates.row -= 1;
				break;
			case 2:
				coordinates.column -= 1;
				break;
			case 3:
				coordinates.column += 1;
				break;
		}

		gameObject.setPosition(
			this.sceneController.blockManager.getPositionFromGridCoordinates(coordinates)
		);

	};

	onCollect(){

		this.incrementCollectedBubbleCount();

		this.numBubblesInPlay -= 1;

		if(this.numEnemiesInPlay <= 0){
			this.emitBubble();
		}

		this.comboBubbles += 1;

	};

	onLand(){

		// Reset comboBubbles
		this.comboBubbles = 0;

	};

	onEnemyDefeat(){

		this.numEnemiesInPlay -= 1;

	};

	incrementCollectedBubbleCount(){

			this.numBubblesCollected += 1;

			const numberString = `${this.numBubblesCollected}`.padStart(7);

			this.sceneController.screenText.components.JuneTextComponent.updateText(numberString);

	};

	setIcons(params){

		this.sceneController.leftIcon.components.JuneSpriteComponent.updateUvsForTextureKey(params.left);
		this.sceneController.rightIcon.components.JuneSpriteComponent.updateUvsForTextureKey(params.right);

	};

	updateMaxBubbles(params){
		this.maxBubbles = params.count;
	}

	makeBubbles(params){

		for(let i = 0; i < params.count; i++){

			this.emitBubble();

		}

	};

	readNextWave(){
		
		this.waveIndex += 1;

		this.maxBubbles += 1;
		
		const wave = this.waves[this.waveIndex];
		const enemies = wave.params.enemies;

		// Make enemies
		for(let x = 0; x < enemies.length; x++){

			for(let y = 0; y < enemies[x].count; y++){

				const enemy = this.sceneController.makeEnemy({
					type: enemies[x].type
				});

				this.emitGameObject(enemy);
				this.numEnemiesInPlay += 1;

			}

		}

	};

	// Use for making extra enemies outside of whats described in wave data.
	// Mostly for when certain enemies (like snails) spawn new bubbles.
	// Makes sure new enemies don't spawn infinitely.
	makeAdHocEnemy(type){

		// Don't make enemy if already maxed out
		if(this.numEnemiesInPlay >= this.maxEnemies){
			return null;
		}

		// Make enemy and increment count
		this.numEnemiesInPlay += 1;
		const enemy = this.sceneController.makeEnemy({
			type: type
		});

		return enemy;

	};

	cleanup(){

	};

	emitParticles({color}){

		const numParticles = 2;
		const maxSpeed = 240.0;

		for(let i = 0; i < numParticles; i++){

			const particle = this.sceneController.getFreeParticle();

			// It's possible that no particle is returned because too many are already active
			if(particle){
				particle.components.ParticleComponent.activate();
				particle.components.ParticleComponent.ttl = 1.0;
				particle.components.ParticleComponent.friction = 0.95;

				// Emit from random emitter
				const randNum = Math.floor(Math.random() * this.emitters.length);
				this.emitters[randNum]

				particle.setPosition(
					helper.addVectors(
						this.emitters[randNum].object3D.position,
						{
							x: helper.randomRange(-24, 24),
							y: helper.randomRange(-24, 24),
							z: 0.0
						}
					)
				);
				const direction = this.emitters[randNum].components.EmitterComponent.getDirectionVector();
				const newVel = helper.scaleVector(
					direction,
					helper.randomRange(maxSpeed * 0.5, maxSpeed)
				);

				particle.components.ParticleComponent.velocity.set(
					newVel.x, newVel.y, newVel.z
				);

				// Update particle color
				particle.components.JuneSpriteComponent.updateUvsForTextureKey(`particle_${color}.png`);

			}
		}

	};


	//====================================================================================================
	// Update loop
	//====================================================================================================


	handleEnemyPhase(){

		// Emit enemy color particles
		this.emitParticles(
			{
				color: this.waves[this.waveIndex].params.color
			}
		);

		// End phase when all enemies are gone
		if(this.numEnemiesInPlay <= 0){

			// Go back to bubblePhase if at least one wave is left
			if(this.waveIndex < this.waves.length - 1){

				this.state = "bubblePhase";
				this.timer = this.bubblePhaseDuration;
				this.emitMaxBubbles();

				// Play SFX
				this.sceneController.app.audioController.play("wave_clear_jingle.wav");

			}else{

				// Clear course
				this.sceneController.app.gotoResultFromGame({won: true});

			}

		}

	};

	handleBubblePhase(){

		this.timer -= this.sceneController.app.dt;

		// Emit particles N seconds before next wave starts
		if(this.timer <= this.bubblePhaseEndWarningDuration){
			this.emitParticles(
				{
					color: this.waves[this.waveIndex + 1].params.color
				}
			);
		}

		// Start enemyPhase
		if(this.timer <= 0){

			this.state = "enemyPhase";
			this.readNextWave();

		}

	};

	update(){

		switch(this.state){

			case "enemyPhase":
				this.handleEnemyPhase();
				break;

			case "bubblePhase":
				this.handleBubblePhase();
				break;

		}

	};

};

module.exports = ModeProgression;


//====================================================================================================
// EOF
//====================================================================================================
