//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// WinningModules

// App
import  BubbleComponent from "./BubbleComponent.js";
import  constants from "./common/constants.js";
import  JuneColliderComponent from "./JuneColliderComponent.js";
import  JuneGameObject from "./JuneGameObject.js";
import  JuneSpriteComponent from "./JuneSpriteComponent.js";
import  helper from "./helper.js";



//====================================================================================================
// ModeBubbleRace
//====================================================================================================


class ModeBubbleRace{

	constructor(sceneController){

		this.sceneController = sceneController;
		this.timer = 0.0;
		this.remainingBubbles = 0;

		this.makeBubbles = this.makeBubbles.bind(this);
		this.makeBubble = this.makeBubble.bind(this);
		this.makeEnemies = this.makeEnemies.bind(this);

	};

	initialize(bubbleData, enemyData){

		this.makeBubbles(bubbleData);
		this.makeEnemies(enemyData);
		this.remainingBubbles = bubbleData.length;

	};

	makeEnemies(enemyData){

		const that = this;

		enemyData.forEach(function(ed){
			that.sceneController.makeEnemy({
				column: ed.c,
				row: ed.r
			});
		});

	}

	makeBubbles(bubbleData){

		const that = this;

		bubbleData.forEach(function(bd){

			that.makeBubble(bd);
			that.makeBubble(bd);

		});

	};

	makeBubble(bd){

		const bubble = new JuneGameObject(`bubble-${helper.uuidgen()}`, this.sceneController);
		bubble.addComponent(
			new BubbleComponent({
				gameObject: bubble,
				onCollectFunc: this.onCollect
			})
		);
		bubble.addComponent(
			new JuneColliderComponent({
				gameObject: bubble,
				size: 64
			})
		);
		const bubbleSpeed = 1.0;
		bubble.components.JuneColliderComponent.velocity.set(
			helper.randomRange(-bubbleSpeed, bubbleSpeed),
			helper.randomRange(-bubbleSpeed, bubbleSpeed),
			0.0
		);
		bubble.addComponent(
			new JuneSpriteComponent({
				gameObject: bubble,
				size: 64,
				texture: "bubble.png"
			})
		);
		this.sceneController.addGameObject(bubble);
		bubble.setPosition(
			this.sceneController.blockManager.getPositionFromGridCoordinates({
				column: bd.c,
				row: bd.r
			})
		);

	}

	onCollect(){

		this.makeBubble({
			c: 1,
			r: 0
		});

	}

	cleanup(){

	};


	//====================================================================================================
	// Update loop
	//====================================================================================================

	update(){

	};

};

module.exports = ModeBubbleRace;


//====================================================================================================
// EOF
//====================================================================================================
