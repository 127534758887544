//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// WinningModules
import  JuneComponent from "./JuneComponent.js";

// App
import  constants from "./common/constants.js";
import  helper from "./helper.js";


//====================================================================================================
// PlayerBulletComponent
//====================================================================================================

const STAR_ROTATION_SPEED = 6.0;

class PlayerBulletComponent extends JuneComponent{

	constructor(params){

		super(params);

		this.gameObject.components.JuneColliderComponent.velocity.set(
			params.velocity.x,
			params.velocity.y,
			params.velocity.z
		);

	};

	onTriggerEnter2D({gameObject, intersectionObject}){

		// Hit block
		if(gameObject.components.BlockComponent){
	
			// Play SFX
			// this.gameObject.sceneController.app.audioController.play("explosion.wav");
	
			// Remove bullet
			this.gameObject.onDelete();
	
		}

		// Hit enemy
		if(gameObject.components.EnemyComponent){

			// Play SFX
			// this.gameObject.sceneController.app.audioController.play("explosion.wav");

			// Damage enemy
			gameObject.components.DamageTakerComponent.damage(1.0);

			// Remove bullet
			this.gameObject.onDelete();

		}

		// Hit bubble
		// if(gameObject.components.BubbleComponent){
		// 	gameObject.components.BubbleComponent.onCollect();
		// }
	
	};

	makeParticles(){

		const numParticles = 1;

		for(let i = 0; i < numParticles; i++){

			const particle = this.gameObject.sceneController.getFreeParticle();

			// It's possible that no particle is returned because too many are already active
			if(particle){
				particle.components.ParticleComponent.activate();
				particle.components.ParticleComponent.ttl = 0.25;

				particle.setPosition(
					helper.addVectors(
						this.gameObject.object3D.position,
						{
							x: helper.randomRange(-24, 24),
							y: helper.randomRange(-24, 24),
							z: 0.0
						}
					)
				);

				// Update particle color
				particle.components.JuneSpriteComponent.updateUvsForTextureKey("star_particle.png");

			}
		}

	}

	update(){

		this.makeParticles();

		this.gameObject.setRotation({
			x: 0.0,
			y: 0.0,
			z: this.gameObject.object3D.rotation.z + (STAR_ROTATION_SPEED * this.app.dt)
		});

	};

};


module.exports = PlayerBulletComponent;


//====================================================================================================
// EOF
//====================================================================================================