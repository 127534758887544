//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// June
import  JuneComponent from "./JuneComponent.js";

// App
import  constants from "./common/constants.js";
import  helper from "./helper.js";


//====================================================================================================
// StarItemComponent
//====================================================================================================


class StarItemComponent extends JuneComponent{

	constructor(params){

		super(params);
		
		this.onCollect = this.onCollect.bind(this);

	};

	onTriggerEnter2D({gameObject, intersectionObject}){

		// Hit player
		if(gameObject.components.PlayerComponent){
			this.onCollect();
		}

	};

	onCollect(){

		// Report back to Mode
		this.gameObject.sceneController.activeMode.onCollect();

		// Give player 3 stars
		this.gameObject.sceneController.bgdm.components.PlayerComponent.stars = 3;

		// Play SFX
		this.gameObject.sceneController.app.audioController.play("bubble_collect.wav");

		// Make particles
		this.makeParticles();

		// Remove object
		this.gameObject.onDelete();

	};

	makeParticles(){

		const numParticles = 16;
		const step = (Math.PI * 2.0) / numParticles;
		const radius = 24;
		const maxSpeed = 60.0;

		for(let i = 0; i < numParticles; i++){

			const particle = this.gameObject.sceneController.getFreeParticle();

			// It's possible that no particle is returned because too many are already active
			if(particle){
				particle.components.ParticleComponent.activate();
				particle.components.ParticleComponent.ttl = 1.0;
				particle.components.ParticleComponent.friction = 0.95;

				// Set position and rotation
				const rads = i * step;
				const direction = {
					x: Math.cos(rads),
					y: Math.sin(rads),
					z: 0.0
				};

				particle.setPosition(
					helper.addVectors(
						this.gameObject.object3D.position,
						helper.scaleVector(
							direction,
							radius
						)
					)
				);
				const newVel = helper.scaleVector(
					direction,
					helper.randomRange(maxSpeed * 0.5, maxSpeed)
				);

				particle.components.ParticleComponent.velocity.set(
					newVel.x, newVel.y, newVel.z
				);

				// Update particle color
				particle.components.JuneSpriteComponent.updateUvsForTextureKey("particle_pink.png");

			}
		}

	}

	onDelete(){
	}

};

module.exports = StarItemComponent;


//====================================================================================================
// EOF
//====================================================================================================