//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// WinningModules
import JuneComponent from "./JuneComponent.js";
import JuneSpriteController from "./JuneSpriteController.js";

// App
import constants from "./common/constants.js";


//====================================================================================================
// JuneSpriteComponent
//====================================================================================================

// A component that makes and holds reference to verts/uv in JuneSpriteController
// In charge of updating verts/uvs when gameObject position/rotation changes


class JuneSpriteComponent extends JuneComponent{

	constructor(params){

		super(params);

		this.dynamic = params.dynamic || false;

		this.size = params.size;
		this.halfSize = this.size * 0.5;
		this.scale = {x: 1, y: 1};

		this.visible = true;

		this.sprite = this.gameObject.sceneController.spriteController.getFreeSprite();
		this.masterMesh = this.gameObject.sceneController.spriteController.masterMesh;
		this.imageRecordMap = this.app.textureAtlas.imageRecordMap;

		// Store last 4 vertices and last 2 faces
		this.vertexIndex = this.sprite.vertexIndex;
		this.faceIndex = this.sprite.faceIndex;
		this.v0 = this.masterMesh.geometry.vertices[this.vertexIndex];
		this.v1 = this.masterMesh.geometry.vertices[this.vertexIndex+1];
		this.v2 = this.masterMesh.geometry.vertices[this.vertexIndex+2];
		this.v3 = this.masterMesh.geometry.vertices[this.vertexIndex+3];

		// Use locators to more easily determine world coordinates of masterMesh vertices
		this.vertexTopLeftLocator = new three.Object3D();
		this.gameObject.object3D.add(this.vertexTopLeftLocator);

		this.vertexTopRightLocator = new three.Object3D();
		this.gameObject.object3D.add(this.vertexTopRightLocator);

		this.vertexBottomLeftLocator = new three.Object3D();
		this.gameObject.object3D.add(this.vertexBottomLeftLocator);

		this.vertexBottomRightLocator = new three.Object3D();
		this.gameObject.object3D.add(this.vertexBottomRightLocator);

		this.vertexTopLeftLocator.position.set(-this.halfSize, this.halfSize, 0.0);
		this.vertexTopRightLocator.position.set(this.halfSize, this.halfSize, 0.0);
		this.vertexBottomLeftLocator.position.set(-this.halfSize, -this.halfSize, 0.0);
		this.vertexBottomRightLocator.position.set(this.halfSize, -this.halfSize, 0.0);

		// Set texture
		this.updateUvsForTextureKey(params.texture);

		// Update master mesh
		this.updateMasterMesh();

	};

	updateMasterMesh(){

		this.masterMesh.geometry.verticesNeedUpdate = true;
		this.masterMesh.geometry.colorsNeedUpdate = true;
		this.masterMesh.geometry.elementsNeedUpdate = true;

	}

	setColor(color){

		this.masterMesh.geometry.faces[this.faceIndex].color.copy(color);
		this.masterMesh.geometry.faces[this.faceIndex+1].color.copy(color);
		this.updateMasterMesh();

	};

	getColor(){

		return this.masterMesh.geometry.faces[this.faceIndex].color;

	};

	updateUvsForTextureKey(textureKey){

		try{
			this.textureKey = textureKey;
			const padding = 0.0001;
			const imageRecord = this.imageRecordMap[textureKey];
			const left = imageRecord.uvLeft + padding;
			const right = imageRecord.uvRight - padding;
			const top = imageRecord.uvTop - padding;
			const bottom = imageRecord.uvBottom + padding;

			this.masterMesh.geometry.faceVertexUvs[0][this.faceIndex][0].set(left, top);
			this.masterMesh.geometry.faceVertexUvs[0][this.faceIndex][1].set(left, bottom);
			this.masterMesh.geometry.faceVertexUvs[0][this.faceIndex][2].set(right, top);
			this.masterMesh.geometry.faceVertexUvs[0][this.faceIndex+1][0].set(left, bottom);
			this.masterMesh.geometry.faceVertexUvs[0][this.faceIndex+1][1].set(right, bottom);
			this.masterMesh.geometry.faceVertexUvs[0][this.faceIndex+1][2].set(right, top);

			this.masterMesh.geometry.uvsNeedUpdate = true;
		}catch(err){
			console.log(`can't find textureKey: ${textureKey}`);
			console.log(`valid textures: `);
			console.log(Object.keys(this.imageRecordMap).length);
			console.log(this.imageRecordMap);
			for(var k in Object.keys(this.imageRecordMap)){
				console.log(k);
			}
			console.log(err);
		}

	};

	updateVertices(){

		// Collapse vertices if visible it set to false
		if(this.visible === false){
			const inf = 999999;
			this.masterMesh.geometry.vertices[this.vertexIndex].set(inf, inf, inf);
			this.masterMesh.geometry.vertices[this.vertexIndex+1].set(inf, inf, inf);
			this.masterMesh.geometry.vertices[this.vertexIndex+2].set(inf, inf, inf);
			this.masterMesh.geometry.vertices[this.vertexIndex+3].set(inf, inf, inf);
			this.updateMasterMesh();
			return;
		}

		// Dynamic is for sprites that rotate. This requires expensive matrix updates.
		// If the sprite isn't going to rotate, set dynamic to false for faster updates.
		if(this.dynamic){
			// Update matrix for locator objects
			this.gameObject.object3D.updateMatrix(true);
			this.gameObject.object3D.updateMatrixWorld(true);

			// Top left
			this.vertexTopLeftLocator.getWorldPosition(this.masterMesh.geometry.vertices[this.vertexIndex]);
			// Top right
			this.vertexTopRightLocator.getWorldPosition(this.masterMesh.geometry.vertices[this.vertexIndex+1]);
			// Bottom left
			this.vertexBottomLeftLocator.getWorldPosition(this.masterMesh.geometry.vertices[this.vertexIndex+2]);
			// Bottom right
			this.vertexBottomRightLocator.getWorldPosition(this.masterMesh.geometry.vertices[this.vertexIndex+3]);
		}else{

			const pos = this.gameObject.object3D.position;

			// top left
			this.v0.set(
				pos.x - (this.halfSize * this.scale.x),
				pos.y + (this.halfSize * this.scale.y),
				pos.z
			);
			// top right
			this.v1.set(
				pos.x + (this.halfSize * this.scale.x),
				pos.y + (this.halfSize * this.scale.y),
				pos.z
			);
			// bottom left
			this.v2.set(
				pos.x - (this.halfSize * this.scale.x),
				pos.y - (this.halfSize * this.scale.y),
				pos.z
			);
			// bottom right
			this.v3.set(
				pos.x + (this.halfSize * this.scale.x),
				pos.y - (this.halfSize * this.scale.y),
				pos.z
			);

		}

		this.updateMasterMesh();
	};

	onUpdatePosition(){
		this.updateVertices();
	};

	onUpdateRotation(){
		this.updateVertices();
	};

	onUpdateVisibility(){
		this.visible = this.gameObject.visible;
		this.updateVertices();
	};

	onDelete(){

		// Set invisible
		this.visible = false;
		this.updateVertices();

		// Free up sprite reference
		this.gameObject.sceneController.spriteController.setFreeSprite(this.sprite);

	}

};

module.exports = JuneSpriteComponent;


//====================================================================================================
// EOF
//====================================================================================================