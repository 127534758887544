//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// WinningModules
import JuneGameObject from "./JuneGameObject.js";
import JuneComponent from "./JuneComponent.js";

// App
import constants from "./common/constants.js";
import helper from "./helper.js";
import JuneSpriteComponent from "./JuneSpriteComponent.js";


//====================================================================================================
// DottedLineComponent
//====================================================================================================

class DottedLineComponent extends JuneComponent{

	constructor(params){

		super(params);

		// Cursor
		this.cursor = new JuneGameObject("cursor", this.gameObject.sceneController);
		this.cursor.addComponent(
			new JuneSpriteComponent({
				gameObject: this.cursor,
				size: 64,
				texture: params.cursorTexture
			})
		);
		this.gameObject.sceneController.addGameObject(this.cursor);

		// Dots
		this.lineTarget = null;
		this.dots = [];
		this.NUM_DOTS = 32;
		this.LINE_LENGTH = 2000.0;
		this.DOT_RADIUS = 5.0;
		this.DOT_SPACING = this.LINE_LENGTH / this.NUM_DOTS;
		for(let d = 0; d < this.NUM_DOTS; d++){
			let dot = new JuneGameObject(`dot-${d}`, this.gameObject.sceneController);
			dot.addComponent(
				new JuneSpriteComponent({
					gameObject: dot,
					size: 16,
					texture: params.dotTexture
				})
			);
			this.gameObject.sceneController.addGameObject(dot);
			this.dots.push(dot);
		}

		// Bindings
		this.drawDottedLine = this.drawDottedLine.bind(this);
		this.clearDots = this.clearDots.bind(this);

	};

	drawDottedLine(origin, angle, remainingLength, reflectCount, dotsUsed){

		// Cast rays and draw dots until length is reached
		angle += 1.57;
		const direction = new three.Vector3(Math.cos(angle), Math.sin(angle), 0.0);
		const blockManager = this.gameObject.sceneController.blockManager;

		// calculate objects intersecting the picking ray
		this.lineTarget = blockManager.getBlockCollisionFromRay(origin, direction);

		// Skip if no target
		if(!this.lineTarget){return;}
		
		// Use new origin to start dots in front of ship
		const dotOrigin = helper.addVectors(
			origin,
			helper.scaleVector(
				direction,
				blockManager.BLOCK_SIZE
			)
		);

		const dotEndOffset = blockManager.BLOCK_SIZE * 2.25;

		// Don't draw dots if right next to target block
		if(this.lineTarget.distance < blockManager.BLOCK_SIZE){
			this.cursor.setVisible(false);
			this.clearDots();
			return;
		}

		if(this.lineTarget){

			let segmentDotsUsed = 0;
			let distance = this.lineTarget.distance - dotEndOffset;

			// Draw cursor to side of block depending on intersection normal
			this.cursor.setVisible(true);
			const cursorPos = helper.addVectors(
				this.lineTarget.object.userData.gameObject.object3D.position,
				helper.scaleVector(
					this.lineTarget.face.normal,
					blockManager.BLOCK_SIZE
				)
			);
			cursorPos.z = constants.Z_UI;
			this.cursor.setPosition(
				cursorPos
			);

			// Clear dots
			this.clearDots();

			// Place dots
			while(true){

				const offset = helper.scaleVector(
					direction,
					segmentDotsUsed * this.DOT_SPACING
				)

				const dot = this.dots[dotsUsed];
				const dotPos = helper.addVectors(
					dotOrigin,
					offset
				);
				dotPos.z = constants.Z_UI;

				dot.setPosition(
					dotPos
				);

				dot.setVisible(true);

				dotsUsed += 1;
				segmentDotsUsed += 1;
				distance -= this.DOT_SPACING;
				remainingLength -= this.DOT_SPACING;

				// Break conditions
				if(distance <= 0.0){
					// TODO: Calculate new angle and draw
					break;
				}
				if(remainingLength <= 0.0){
					break;
				}
				if(dotsUsed >= this.NUM_DOTS){
					break;
				}
			}
		}
		
	};

	clearDots(){

		this.dots.forEach(function(d){
			d.setVisible(false);
		});
	}

	clearAll(){

		this.clearDots();
		this.cursor.setVisible(false);

	}

	onDelete(){

		// Remove cursor
		this.cursor.onDelete();

		// Remove dots
		this.dots.forEach(function(d){
			d.onDelete();
		});
		this.dots = null;

	}

};

module.exports = DottedLineComponent;


//====================================================================================================
// EOF
//====================================================================================================