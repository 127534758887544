//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// WinningModules
import  JuneComponent from "./JuneComponent.js";

// App
import  constants from "./common/constants.js";
import  helper from "./helper.js";
import  { Vector2 } from "three";


//====================================================================================================
// MoveToPoint
//====================================================================================================


class MoveToPoint extends JuneComponent{

	constructor(params){

		super(params);

		this.destination = params.destination;
		this.speed = params.speed;
		this.stopThreshold = params.stopThreshold;
		this.ease = params.ease || false;
		this.reachedDestinationFunc = null;
    };
    
    update(){

		if(!this.destination){return;}

		// Gather initial data
		const diff = helper.subVectors(
			this.destination,
			this.gameObject.object3D.position
		);

		const distance = helper.getVectorLength(diff);
		let velocity = null;
		
		if(this.ease){
			velocity = helper.scaleVector(
				diff,
				this.speed
			);
		}else{
			velocity = helper.scaleVector(
				helper.normalizeVector(diff),
				this.speed
			);
		}

		this.gameObject.components.JuneColliderComponent.velocity.set(
			velocity.x,
			velocity.y,
			velocity.z
		);

		// Snap to point when close enough
		if(distance < this.stopThreshold){

			this.gameObject.setPosition(this.destination);
			this.gameObject.components.JuneColliderComponent.velocity.set(0.0, 0.0, 0.0);
			this.destination = null;
			if(this.reachedDestinationFunc){
				this.reachedDestinationFunc();
			}

		}else{

		}

    };

};

module.exports = MoveToPoint;


//====================================================================================================
// EOF
//====================================================================================================