Constants = {

	// Browser
	GAME_WIDTH: 750, 
	GAME_HEIGHT: 1334,

	SCREEN_WIDTH_MAX: 1920.0,
	SCREEN_HEIGHT_MAX: 1080.0,

	// ORIENTATIONS
	ORIENTATION_PORTRAIT: 0,
	ORIENTATION_LANDSCAPE: 1,

	// EDIT STATES
	EDIT_MODE_SELECT: 0,
	EDIT_MODE_DRAW: 1,
	EDIT_MODE_DELETE: 2,

	EDIT_MODE_PAINT_BLOCK: 0,
	EDIT_MODE_PAINT_BUBBLE: 1,

	// GAME OBJECT TYPES
	TYPE_BLOCK: 1,
	TYPE_PROJECTILE: 2,
	TYPE_ENEMY: 3,
	TYPE_ITEM: 4,
	TYPE_PLAYER: 5,
	TYPE_COLLECTABLE: 6,

	// EFFECT TYPES
	EFFECT_TYPE_RICOCHET: 0,

	// SPIDERS
	NUM_SPIDERS: 16,

	// ITEMS
	NUM_ITEMS: 16,

	// PROJECTILES
	NUM_PROJECTILES: 512,

	// Z
	Z_UI: 1.0,
	Z_FRONT: 0.0,
	Z_BLOCK: 0.0,
	Z_BLOCK_OUTLINE: -3.0,
	Z_CLOUD: -4.0,
	Z_CLOUD_BG: -5.0,

	// LAYERS
	LAYER_MAIN: 1,
	LAYER_COLLISION: 2,

	// BLOCK TYPES
	BLOCK_TYPE_CLOUD: 0,
	BLOCK_TYPE_BIG_GUM_1: 1,
	BLOCK_TYPE_BIG_GUM_2: 2,
	BLOCK_TYPE_BIG_GUM_3: 3,
	BLOCK_TYPE_BIG_GUM_4: 4,
	BLOCK_TYPE_BIG_GUM_5: 5,
	BLOCK_TYPE_EMITTER: 6,
	BLOCK_TYPE_INVISIBLE: 7,

	// PHYSICS
	LINE_SNAP_INCREMENT: 25.0,
	GRAVITY: 4.0,
	CHARACTER_FRICTION: 0.75,
	CHARACTER_HORIZONTAL_FORCE: 2.0,
	CHARACTER_DASH_FORCE: 2.0,
	CHARACTER_JUMP_FORCE: 40.0,
	CHARACTER_WIDTH: 64.0,
	CHARACTER_HALF_WIDTH: 32.0,
	CHARACTER_HEIGHT: 64.0,
	CHARACTER_HALF_HEIGHT: 32.0,

	// ITEM TYPES
	ITEM_TYPE_SPREAD: 0,

	// DOTTED LINE


	// STATUS CODES
	STATUS_CODE_SUCCESS: 1,
	STATUS_CODE_FAIL: 2,

	// COLORS
	COLOR_BLUE_BLOCK: 0x99ffff,
	COLOR_BLUE_BLOCK_OUTLINE: 0x0099cc,
	COLOR_GREEN_BLOCK: 0x99ff99,
	COLOR_GREEN_BLOCK_OUTLINE: 0x00cc00,
	COLOR_PINK_BLOCK: 0xff99ff,
	COLOR_PINK_BLOCK_OUTLINE: 0xcc0099,
	COLOR_GREY_BACKGROUND: 0x333333,
	COLOR_PINK: 0xff0099,
	COLOR_PINK_OUTLINE: 0x660033,
	COLOR_PURPLE: 0x9900ff,
	COLOR_PURPLE_OUTLINE: 0x330066,
	COLOR_GREY_SKY: 0x333333,
	COLOR_DARK_BLUE_SKY: 0x000033,
	COLOR_RING_OUTLINE: 0x003366,
	COLOR_RING_CENTER: 0x0099ff,
	COLOR_GREY_CASE: 0x333333,
	COLOR_LIGHT_PINK: 0xffccff

}


module.exports = Constants;