//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// WinningModules
import  JuneComponent from "./JuneComponent.js";
import  JuneSpriteController from "./JuneSpriteController.js";
import  JuneGameObject from "./JuneGameObject.js";
import  JuneSpriteComponent from "./JuneSpriteComponent.js";
import  helper from "./helper.js";

// App
import  constants from "./common/constants.js";


//====================================================================================================
// JuneTextComponent
//====================================================================================================

// A component that controls a set of gameObjects with SpriteComponents to render text


class JuneTextComponent extends JuneComponent{

	constructor(params){

		super(params);

		this.size = params.size;
		this.text = params.text;
		this.halfSize = this.size * 0.5;

		this.visible = true;

		// Make sprites
		this.spriteList = [];
		for(let i = 0; i < this.text.length; i++){;

			const characterObject = new JuneGameObject(helper.uuidgen(), this.gameObject.sceneController);
			characterObject.addComponent(
				new JuneSpriteComponent({
					gameObject: characterObject,
					size: 64,
					texture: this.text[i]
				})
			);
			this.gameObject.sceneController.addGameObject(characterObject);
			this.spriteList.push(characterObject);

		}

		this.onUpdatePosition();

	};

	updateText(text){

		this.text = text;

		for(let i = 0; i < this.spriteList.length; i++){
			this.spriteList[i].components.JuneSpriteComponent.updateUvsForTextureKey(this.text[i] || " ");
		}

	};
	
	onUpdatePosition(){
		
		const width = this.text.length * this.size;
		const position = this.gameObject.object3D.position;
		const startPosX = this.gameObject.object3D.position.x + (this.size * 0.5) - (width * 0.5);
		for(let i = 0; i < this.spriteList.length; i++){

			this.spriteList[i].setPosition({
				x: startPosX + (i * this.size),
				y: position.y,
				z: position.z
			});
		}
	};


	onUpdateVisibility(){

		for(let i = 0; i < this.spriteList.length; i++){
			this.spriteList[i].setVisible(this.gameObject.visible);
		}

	};

	setColor(color){

		for(let i = 0; i < this.spriteList.length; i++){
			this.spriteList[i].components.JuneSpriteComponent.setColor(color);
		}

	};

	onDelete(){

		for(let i = 0; i < this.spriteList.length; i++){
			this.spriteList[i].onDelete();
		}
		spriteList = null;

	}

};

module.exports = JuneTextComponent;


//====================================================================================================
// EOF
//====================================================================================================