//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// WinningModules
import  JuneComponent from "./JuneComponent.js";

// App
import  constants from "./common/constants.js";
import  helper from "./helper.js";
import  MoveToPoint from "./MoveToPoint.js";


//====================================================================================================
// EnemyComponent
//====================================================================================================

const ENEMY_STATE_IDLE = 0;
const ENEMY_STATE_ROTATING = 1;
const ENEMY_STATE_PRE_JUMPING = 2;
const ENEMY_STATE_JUMPING = 3;

const IDLE_TIME = 60;
const PRE_JUMPING_TIME = 60;
const ROTATION_SPEED = 0.05;

class EnemyComponent extends JuneComponent{

	constructor(params){

		super(params);

		this.color = params.color;
		this.defeatSfx = params.defeatSfx;

		this.makeParticles = this.makeParticles.bind(this);
		this.onDefeat = this.onDefeat.bind(this);

	};

	onTriggerEnter2D({gameObject, intersectionObject}){

		// Hit enemies
		if(gameObject.components.PlayerComponent){
			this.gameObject.sceneController.bgdm.components.DamageTakerComponent.damage(1.0);
			// this.gameObject.sceneController.startGameOver();
		}

	};

	onDefeat(){

		// Play SFX
		this.gameObject.sceneController.app.audioController.play(this.defeatSfx);

		// Particles
		this.makeParticles();

		// Inform mode
		this.gameObject.sceneController.activeMode.onEnemyDefeat();

		// Delete gameObject
		this.gameObject.onDelete();

	};

	makeParticles(){

		const numParticles = 16;
		const step = (Math.PI * 2.0) / numParticles;
		const radius = 24;
		const maxSpeed = 60.0;

		for(let i = 0; i < numParticles; i++){

			const particle = this.gameObject.sceneController.getFreeParticle();

			// It's possible that no particle is returned because too many are already active
			if(particle){
				particle.components.ParticleComponent.activate();
				particle.components.ParticleComponent.ttl = 1.0;
				particle.components.ParticleComponent.friction = 0.95;

				// Set position and rotation
				const rads = i * step;
				const direction = {
					x: Math.cos(rads),
					y: Math.sin(rads),
					z: 0.0
				};

				particle.setPosition(
					helper.addVectors(
						this.gameObject.object3D.position,
						helper.scaleVector(
							direction,
							radius
						)
					)
				);
				const newVel = helper.scaleVector(
					direction,
					helper.randomRange(maxSpeed * 0.5, maxSpeed)
				);

				particle.components.ParticleComponent.velocity.set(
					newVel.x, newVel.y, newVel.z
				);

				// Update particle color
				particle.components.JuneSpriteComponent.updateUvsForTextureKey(`particle_${this.color}.png`);

			}
		}

	};

};

module.exports = EnemyComponent;


//====================================================================================================
// EOF
//====================================================================================================