//====================================================================================================
// IMPORTS
//====================================================================================================


// Third-party
import * as three from "three";

// WinningModules
import JuneApp from "./JuneApp.js";

// App
import constants from "./common/constants.js";

import JuneTextureAtlas from "./JuneTextureAtlas.js";

// SceneControllers
import LoadingSceneController from "./LoadingSceneController.js";
import GameSceneController from "./GameSceneController.js";
import TitleSceneController from "./TitleSceneController.js";
import SplashSceneController from "./SplashSceneController.js";
import ResultSceneController from "./ResultSceneController.js";
import JuneAudioController from "./JuneAudioController.js";


//====================================================================================================
// Setup app
//====================================================================================================


const app = new JuneApp({
	gameWidth: constants.GAME_WIDTH,
	gameHeight: constants.GAME_HEIGHT,
	supportedOrientations: [constants.ORIENTATION_PORTRAIT]
});


//====================================================================================================
// Initialization
//====================================================================================================


function docReady(fn){

	// see if DOM is already available
	if(document.readyState === "complete" || document.readyState === "interactive"){
		// call on next available tick
		setTimeout(fn, 1);
	}else{
		document.addEventListener("DOMContentLoaded", fn);
	}
}    

async function initSequence(){

	app.addEventListeners();

	// Scene
	app.initializeRenderer();

	// Setup sceneControllers
	app.sceneControllers.loadingSceneController = new LoadingSceneController(app);
	app.sceneControllers.splashSceneController = new SplashSceneController(app);
	app.sceneControllers.gameSceneController = new GameSceneController(app);
	app.sceneControllers.titleSceneController = new TitleSceneController(app);
	app.sceneControllers.resultSceneController = new ResultSceneController(app);

	// Setup titleSceneController
	app.activeSceneController = app.sceneControllers.loadingSceneController;
	app.sceneControllers.loadingSceneController.initialize();

	// Start loading sequence

	// Setup audioController
	// Do this after title screen is shown,
	// so loading of audio is done in the background
	app.audioController = new JuneAudioController();
	await app.audioController.requestAudioFiles(
		[
			"bubble_collect.wav",
			"bubble_shoot.wav",
			"explosion.wav",
			"jump.wav",
			"shatter.wav",
			"wave_clear_jingle.wav",
			"may_second.mp3",
			"dizzy_from_losing.mp3",
			"enjoying_yourself.mp3",
			"fanfare.mp3",
			"starshine_magical_brilliance.mp3"
		],
		function(data){
			app.sceneControllers.loadingSceneController.updateLoadingText(
				`Loading audio...${data.current}/${data.total}`
			);
		}
	);

	// Setup imageRecordMap
	app.textureAtlas = new JuneTextureAtlas();
	await app.textureAtlas.loadImages(
		[
			"audio_running.png",
			"audio_suspended.png",
			"big_gum_1.png",
			"big_gum_2.png",
			"big_gum_3.png",
			"big_gum_4.png",
			"big_gum_5.png",
			"blue_crystaline.png",
			"blue_crystaline_flash.png",
			"bubble_small.png",
			"bubble_medium.png",
			"bubble_large.png",
			"bubble_small_purple.png",
			"bubble_medium_purple.png",
			"bubble_large_purple.png",
			"buttons.png",
			"cloud.png",
			"cloud_bump.png",
			"cloud_bump_small.png",
			"cursor.png",
			"cursor_green.png",
			"cursor_blue.png",
			"dot.png",
			"dot_green.png",
			"dot_blue.png",
			"dream_machine.png",
			"dream_machine_flash.png",
			"emitter_up.png",
			"emitter_down.png",
			"emitter_left.png",
			"emitter_right.png",
			"enemy.png",
			"flyer.png",
			"flyer_flash.png",
			"green_bubble.png",
			"green_snail.png",
			"green_snail_flash.png",
			"hearts_0.png",
			"hearts_1.png",
			"hearts_2.png",
			"hearts_3.png",
			"logo.png",
			"miir.png",
			"miir_flash.png",
			"particle_pink.png",
			"particle_purple.png",
			"particle_green.png",
			"particle_blue.png",
			"scan_lines.png",
			"shooting_star.png",
			"star_bubble.png",
			"star_particle.png",
			"star_1.png",
			"star_2.png",
			"star_3.png",
			"top_machine.png",
			"triangle.png"
		],
		function(data){
			app.sceneControllers.loadingSceneController.updateLoadingText(
				`Loading textures...${data.current}/${data.total}`
			);
		}
	);

	// Load font
	const fontFace = new FontFace('Chango', 'url(https://fonts.gstatic.com/s/chango/v9/2V0cKI0OB5U7WaJCyHe5blYKAg.woff2)');
	await fontFace.load();
	document.fonts.add(fontFace);
	app.textureAtlas.fontFace = fontFace;

	// Make sprite sheet
	app.textureAtlas.renderSpriteSheet();

	// Switch to splashSceneController
	app.gotoSplashFromLoading();

	// Start app loop
	mainLoop();
};

docReady(initSequence);


//====================================================================================================
// SceneController transitions
//====================================================================================================


app.gotoSplashFromLoading = async function(e){

	// Clean up loading screen
	app.sceneControllers.loadingSceneController.cleanUp();

	app.sceneControllers.splashSceneController.initialize();

	app.activeSceneController = app.sceneControllers.splashSceneController;

}

app.gotoTitleFromSplash = async function(e){

	// Resume audioContext
	await app.audioController.resumeAudioContext();
	await app.audioController.decodeAudioFiles();

	// Play BGM
	if(app.audioController.audioContext.state === "running"){
		app.audioController.playBgm("fanfare.mp3");
	}

	// Clean up menu
	app.sceneControllers.splashSceneController.cleanUp();

	// Init titleSceneController
	app.sceneControllers.titleSceneController.initialize();

	// Change activeSceneController
	app.activeSceneController = app.sceneControllers.titleSceneController;

};

app.gotoGameFromTitle = async function(e){

	// Play BGM
	if(app.audioController.audioContext.state === "running"){
		app.audioController.playBgm("enjoying_yourself.mp3");
	}

	// Clean up menu
	while(app.sceneControllers.titleSceneController.buttons.length > 0){

		let button = app.sceneControllers.titleSceneController.buttons.pop();

	}

	// Init gameSceneController
	app.sceneControllers.gameSceneController.initialize();

	// Change activeSceneController
	app.activeSceneController = app.sceneControllers.gameSceneController;
};

app.gotoResultFromGame = async function({won}){

	// Play BGM
	if(app.audioController.audioContext.state === "running"){
		if(won){
			app.audioController.playBgm("starshine_magical_brilliance.mp3");
		}else{
			app.audioController.playBgm("dizzy_from_losing.mp3");
		}
	}

	// Clean up scene
	app.sceneControllers.gameSceneController.cleanUp();

	// Init resultSceneController
	app.sceneControllers.resultSceneController.initialize();

	// Change activeSceneController
	app.activeSceneController = app.sceneControllers.resultSceneController;
};

app.gotoGameFromResult = async function(e){

	// Play BGM
	if(app.audioController.audioContext.state === "running"){
		app.audioController.playBgm("enjoying_yourself.mp3");
	}

	// Clean up menu
	while(app.sceneControllers.resultSceneController.buttons.length > 0){

		let button = app.sceneControllers.resultSceneController.buttons.pop();

	}

	// Init gameSceneController
	app.sceneControllers.gameSceneController.restartGame();

	// Change activeSceneController
	app.activeSceneController = app.sceneControllers.gameSceneController;
};


//====================================================================================================
// Main loop
//====================================================================================================


function mainLoop(){

	app.update();

	app.renderer.render(app.activeSceneController.scene, app.activeSceneController.camera);

	requestAnimationFrame(mainLoop);

};


//====================================================================================================
// EOF
//====================================================================================================
