//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// WinningModules

// App
import  BubbleComponent from "./BubbleComponent.js";
import  constants from "./common/constants.js";
import  JuneColliderComponent from "./JuneColliderComponent.js";
import  JuneGameObject from "./JuneGameObject.js";
import  JuneSpriteComponent from "./JuneSpriteComponent.js";
import  helper from "./helper.js";



//====================================================================================================
// ModeZoneOut
//====================================================================================================


class ModeZoneOut{

	constructor(sceneController){

		this.sceneController = sceneController;

		this.maxBubbles = 16;

		this.emitters = this.sceneController.blockManager.getEmitters();

		this.numBubblesCollected = 0;

		this.emit = this.emit.bind(this);
		this.onCollect = this.onCollect.bind(this);

	};

	initialize(){

		for(let i = 0; i < this.maxBubbles; i++){
			this.emit();
		}

	};

	emit(){

		// Choose one
		const randNum = Math.floor(Math.random() * this.emitters.length);

		// Emit from that one
		this.emitters[randNum].components.EmitterComponent.emit();

	}

	onCollect(){

		this.emit();

		// Update bubble count and screen
		this.numBubblesCollected += 1;

		const numberString = `x${this.numBubblesCollected}`.padStart(7);

		this.sceneController.screenText.components.JuneTextComponent.updateText(numberString);

	}

	cleanup(){

	};


	//====================================================================================================
	// Update loop
	//====================================================================================================

	update(){

	};

};

module.exports = ModeZoneOut;


//====================================================================================================
// EOF
//====================================================================================================
