//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";


//====================================================================================================
// JuneImageRecordMap
//====================================================================================================


class JuneTextureAtlas{

	constructor(params){

		this.fontFace = null;

		this.masterMaterial = new three.MeshBasicMaterial({
			color: 0x000000
		});

		// Sprite sheet
		this.imageRecordMap = {};

	};

	renderFontImages(){

		// TODO: Better way of getting all ascii characters programatically?
		const ascii = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+-={}|[]\\:\";'<>?,./ ";

		const SIZE = 32;

		for(let character in ascii){

			const canvas = document.createElement("CANVAS");
			canvas.width = SIZE;
			canvas.height = SIZE;
			const ctx = canvas.getContext("2d");
			// ctx.font = "900 14px Monaco";
			ctx.font = `${SIZE}px ${this.fontFace.family}`;
			ctx.fillStyle = "white";
			// ctx.fillText(ascii[character], SIZE * 0.25, SIZE - (SIZE * 0.25));
			ctx.fillText(ascii[character], SIZE * 0.1, SIZE - (SIZE * 0.1));

			const name = ascii[character];
			this.imageRecordMap[name] = {}
			this.imageRecordMap[name].image = canvas;
			this.imageRecordMap[name].name = name;
		}

	};

	async loadImages(imageFilenameList, progressCallback){

		const that = this;

		return new Promise(function(resolve, reject){

			let ctr = 0;
			imageFilenameList.forEach(function(item, key){
	
				var imageLoader = new three.ImageLoader();
				imageLoader.load(
	
					item,
	
					// Function call when completed successfully
					function(image){
						that.imageRecordMap[item] = {};
						that.imageRecordMap[item].image = image;
						that.imageRecordMap[item].name = item;
						
						console.log(`${item} loaded successfully`)
						ctr++;

						// Update progress
						progressCallback({
							total: imageFilenameList.length,
							current: ctr
						});

						// Resolve when all are loaded
						if(ctr == imageFilenameList.length){
							resolve();
						}
					},
	
					// Function called when download progresses
					function(xhr){
					},
	
					// Function called when download errors
					function(xhr){
						reject();
						console.log(`An error happened while loading ${item}`);
					}
				);
	
			});
	
		});
		
	};

	renderSpriteSheet(){

		// Note: canvas coordinates start at top left, UV coordinates start at bottom left

		// Render fonts
		this.renderFontImages();

		// Calculate dimensions
		var requiredArea = 0;
		for(let i in this.imageRecordMap){
			requiredArea += Math.pow(this.imageRecordMap[i].image.width, 2);
		}

		var smallestPowerOfTwoArea = 4;
		while(requiredArea > smallestPowerOfTwoArea){
			smallestPowerOfTwoArea *= 4;
		}

		var requiredWidth = Math.sqrt(smallestPowerOfTwoArea);

		// Create canvas
		const canvas = document.createElementNS("http://www.w3.org/1999/xhtml", "canvas");
		canvas.width = requiredWidth;
		canvas.height = requiredWidth;

		const context = canvas.getContext("2d");

		// Sort images based on size
		const sortedImageList = Object.values(this.imageRecordMap);
		sortedImageList.sort(function(a,b){return b.image.width - a.image.width});

		// Render images to canvas from top left to bottom right
		const usedAreaBoxList = [];
		let x = 0;
		let y = 0;
		for(let i in sortedImageList){
			let imageRecord = sortedImageList[i];

			// Draw image to canvas
			context.drawImage(imageRecord.image, x, y);

			// Make Box2d to reflect used area
			usedAreaBoxList.push(new three.Box2(
				new three.Vector2(x, y),
				new three.Vector2(x + imageRecord.image.width - 1, y + imageRecord.image.width - 1)
			));

			// Save image UVs
			imageRecord.uvLeft = x / canvas.width;
			imageRecord.uvRight = (x + imageRecord.image.width) / canvas.width;
			imageRecord.uvTop = (canvas.height - y) / canvas.height;
			imageRecord.uvBottom = (canvas.height - (y + imageRecord.image.height)) / canvas.height;

			// Increment drawing position
			x += imageRecord.image.width;

			// Start a new row if this image goes beyond the canvas edge
			if(x >= canvas.width){
				x = 0;
				y += imageRecord.image.height;

				while(usedAreaBoxList.filter(box=>{
					return box.containsPoint(new three.Vector2(x, y));}).length >= 1
				){
					x += imageRecord.image.width;
				}
			}

		}

		// Render to new image
		const spriteSheetImage = context.getImageData(0, 0, canvas.width, canvas.height);

		// Create threejs texture
		const texture = new three.Texture();
		texture.image = spriteSheetImage;
		texture.wrapS = three.RepeatWrapping;
		texture.wrapT = three.RepeatWrapping;
		texture.minFilter = three.NearestFilter;
		texture.magFilter = three.NearestFilter;
		texture.needsUpdate = true;
 
		// Update material
		this.masterMaterial = new three.MeshBasicMaterial({
			map: texture,
			vertexColors: three.FaceColors,
			side: three.DoubleSide,
			transparent: true,
			alphaTest: 0.01
		});

		// DEBUG: show sprite sheet in dom
		// document.body.append(canvas);

		// Drop image references
		Object.values(this.imageRecordMap).forEach(function(imageRecord){

			imageRecord.image = null;

		});

	};

};

module.exports = JuneTextureAtlas;


//====================================================================================================
// EOF
//====================================================================================================