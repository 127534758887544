//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// WinningModules
import  JuneComponent from "./JuneComponent.js";

// App
import  constants from "./common/constants.js";
import  helper from "./helper.js";


//====================================================================================================
// StarComponent
//====================================================================================================


class StarComponent extends JuneComponent{

	constructor(params){

		super(params);

		this.velocity = params.velocity;
		this.horizontalBoundary = this.gameObject.sceneController.app.gameWidth * 0.5;
		this.verticalBoundary = this.gameObject.sceneController.app.gameHeight * 0.5;
    };
    
    update(){

		this.gameObject.setPosition(
			helper.addVectors(
				this.gameObject.object3D.position,
				helper.scaleVector(
					this.velocity,
					this.app.dt
				)
			)
		);

		// Wrap
		// Right
		if(this.gameObject.object3D.position.x > this.horizontalBoundary){
			this.gameObject.setPosition({
				x: -this.horizontalBoundary,
				y: this.gameObject.object3D.position.y,
				z: this.gameObject.object3D.position.z
			})
		}
		// Left
		if(this.gameObject.object3D.position.x < -this.horizontalBoundary){
			this.gameObject.setPosition({
				x: this.horizontalBoundary,
				y: this.gameObject.object3D.position.y,
				z: this.gameObject.object3D.position.z
			})
		}
		// Top
		if(this.gameObject.object3D.position.y > this.verticalBoundary){
			this.gameObject.setPosition({
				x: this.gameObject.object3D.position.x,
				y: -this.verticalBoundary,
				z: this.gameObject.object3D.position.z
			})
		}
		// Bottom
		if(this.gameObject.object3D.position.y < -this.verticalBoundary){
			this.gameObject.setPosition({
				x: this.gameObject.object3D.position.x,
				y: this.verticalBoundary,
				z: this.gameObject.object3D.position.z
			})
		}

    };

};

module.exports = StarComponent;


//====================================================================================================
// EOF
//====================================================================================================