import * as three from "three";


function s4(){
	return Math.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1);
};

module.exports.uuidgen = function(){

	return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()

};

module.exports.randomRange = function(min, max){

	const span = max - min;
	return min + Math.random() * span;

}

module.exports.randomFromArray = function(array){

	return array[Math.floor(Math.random() * array.length)];

}

module.exports.colorRange = function(a, b, t){

}

module.exports.scaleVector = function(v1, s){

	return {
		x: v1.x * s,
		y: v1.y * s,
		z: v1.z * s,
	};

}

module.exports.multVectors = function(v1, v2){

	return {
		x: v1.x * v2.x,
		y: v1.y * v2.y,
		z: v1.z * v2.z,
	};

}

module.exports.addVectors = function(v1, v2){

	return {
		x: v1.x + v2.x,
		y: v1.y + v2.y,
		z: v1.z + v2.z,
	};

}

module.exports.subVectors = function(v1, v2){

	return {
		x: v1.x - v2.x,
		y: v1.y - v2.y,
		z: v1.z - v2.z,
	};

}

module.exports.getVectorLength = function(v){

	return Math.sqrt(v.x * v.x + v.y * v.y + v.z * v.z);

}

module.exports.normalizeVector = function(v){

	return new three.Vector3().copy(v).normalize();

}