//====================================================================================================
// Imports
//====================================================================================================


// Third-party

// WinningModules

// App


//====================================================================================================
// JuneComponent
//====================================================================================================


class JuneComponent{

	constructor(params){

		this.gameObject = params.gameObject;

		this.app = this.gameObject.app;

		this.enabled = true;

	};
	
	getName(){
		return this.constructor.name;
	};
    
	update(){
	};

};

module.exports = JuneComponent;


//====================================================================================================
// EOF
//====================================================================================================