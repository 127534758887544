//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";


//====================================================================================================
// Button
//====================================================================================================


class Button{

	constructor({
		onHoverStartFunc,
		onHoverEndFunc,
		onPressFunc,
		onDownFunc,
		onReleaseFunc,
		onCancelFunc,
		position,
		collisionSize,
		z
	}){

		// State
		this.isDownThisFrame = false;
		this.wasDownLastFrame = false;
		this.isHoveredThisFrame = false;
		this.wasHoveredLastFrame = false;

		// Collision box
		this.z = z;
		this.collisionBox = new three.Box2(new three.Vector2(0.0, 0.0), new three.Vector2(0.0, 0.0));
		this.collisionBox.setFromCenterAndSize(position, collisionSize);

		// Functions
		this.onHoverStartFunc = onHoverStartFunc || function(){};
		this.onHoverEndFunc = onHoverEndFunc || function(){};
		this.onPressFunc = onPressFunc || function(){};
		this.onDownFunc = onDownFunc || function(){};
		this.onReleaseFunc = onReleaseFunc || function(){};
		this.onCancelFunc = onCancelFunc || function(){};

	}

};

module.exports = Button


//====================================================================================================
// EOF
//====================================================================================================
