//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// June
import  JuneComponent from "./JuneComponent.js";

// App
import  constants from "./common/constants.js";
import  helper from "./helper.js";


//====================================================================================================
// FloatComponent
//====================================================================================================


class FloatComponent extends JuneComponent{

	constructor(params){

		super(params);
		
	};

	onTriggerEnter2D({gameObject, intersectionObject}){

		// Bounce off blocks
		if(gameObject.components.BlockComponent){

			// Change velocity based on normal
			if(Math.abs(intersectionObject.face.normal.y) === 1.0){
				this.gameObject.components.JuneColliderComponent.velocity.set(
					this.gameObject.components.JuneColliderComponent.velocity.x,
					-this.gameObject.components.JuneColliderComponent.velocity.y,
					0.0
				)
			}
			if(Math.abs(intersectionObject.face.normal.x) === 1.0){
				this.gameObject.components.JuneColliderComponent.velocity.set(
					-this.gameObject.components.JuneColliderComponent.velocity.x,
					this.gameObject.components.JuneColliderComponent.velocity.y,
					0.0
				)
			}

		}

		// Bounce off other floaties
		if(gameObject.components.FloatComponent){

			// Change velocity based on normal and opposing bubble's velocity
			if(Math.abs(intersectionObject.face.normal.y) === 1.0){

				// Self
				const self = this.gameObject.components.JuneColliderComponent.velocity.y;
				const other = gameObject.components.JuneColliderComponent.velocity.y;
				this.gameObject.components.JuneColliderComponent.velocity.set(
					this.gameObject.components.JuneColliderComponent.velocity.x,
					other,
					0.0
				)

				// Other bubble
				gameObject.components.JuneColliderComponent.velocity.set(
					gameObject.components.JuneColliderComponent.velocity.x,
					self,
					0.0
				)
			}
			if(Math.abs(intersectionObject.face.normal.x) === 1.0){

				// Self
				const self = this.gameObject.components.JuneColliderComponent.velocity.x;
				const other = gameObject.components.JuneColliderComponent.velocity.x;
				this.gameObject.components.JuneColliderComponent.velocity.set(
					other,
					this.gameObject.components.JuneColliderComponent.velocity.y,
					0.0
				)

				// Other bubble
				gameObject.components.JuneColliderComponent.velocity.set(
					self,
					gameObject.components.JuneColliderComponent.velocity.y,
					0.0
				)

			}

		}

	};

};

module.exports = FloatComponent;


//====================================================================================================
// EOF
//====================================================================================================