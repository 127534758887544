//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// June
import JuneComponent from "./JuneComponent.js";
import BubbleComponent from "./BubbleComponent.js";
import JuneGameObject from "./JuneGameObject.js";
import JuneSpriteComponent from "./JuneSpriteComponent.js";
import helper from "./helper.js";

// App
import constants from "./common/constants.js";


//====================================================================================================
// EmitterComponent
//====================================================================================================


const threshold = 1.0;
const rate = 10;

const emitDirection = {
	0: new three.Vector3(0.0, 1.0, 0.0),	// Up
	1: new three.Vector3(0.0, -1.0, 0.0),	// Down
	2: new three.Vector3(-1.0, 0.0, 0.0),	// Left
	3: new three.Vector3(1.0, 0.0, 0.0),	// Right
};

class EmitterComponent extends JuneComponent{

	constructor(params){

		super(params);

		this.direction = params.direction;

		// Rotate sprite based on direction
		if(this.direction == 0){
			this.gameObject.components.JuneSpriteComponent.updateUvsForTextureKey("emitter_up.png");
		}
		if(this.direction == 1){
			this.gameObject.components.JuneSpriteComponent.updateUvsForTextureKey("emitter_down.png");
		}
		if(this.direction == 2){
			this.gameObject.components.JuneSpriteComponent.updateUvsForTextureKey("emitter_left.png");
		}
		if(this.direction == 3){
			this.gameObject.components.JuneSpriteComponent.updateUvsForTextureKey("emitter_right.png");
		}
		
	};

	emit(){

		const bubble = this.gameObject.sceneController.getFreeBubble();
		
		if(bubble){

			bubble.components.BubbleComponent.activate();

			bubble.setPosition(
				helper.addVectors(
					this.gameObject.object3D.position,
					helper.scaleVector(
						emitDirection[this.direction],
						this.gameObject.sceneController.blockManager.BLOCK_SIZE * 0.5
					)
				)
			);

		}

	};

	getDirectionVector(){
		return emitDirection[this.direction];
	}

};

module.exports = EmitterComponent;


//====================================================================================================
// EOF
//====================================================================================================