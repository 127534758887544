//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";


//====================================================================================================
// JuneAudioController
//====================================================================================================


class JuneAudioController{

	constructor(params){

		const AudioContext = window.AudioContext || window.webkitAudioContext;
		this.audioContext = new AudioContext();

		this.audioFilenameList = [];
		this.audioBufferMap = {};

		this.bgmSrc = null;
		this.bgmPlaying = false;

		this.resumeAudioContext = this.resumeAudioContext.bind(this);
		this.suspendAudioContext = this.suspendAudioContext.bind(this);
		this.requestAudioFiles = this.requestAudioFiles.bind(this);
		this.decodeAudioFiles = this.decodeAudioFiles.bind(this);

		console.log(this);

	};

	async requestAudioFiles(audioFilenameList, progressCallback){

		const that = this;

		that.audioFilenameList = audioFilenameList;

		return new Promise(function(resolve, reject){

			let ctr = 0;

			that.audioFilenameList.forEach(function(item){

				let req = new XMLHttpRequest(); 
				req.open(
					"GET",
					item,
					true
				); 
				req.responseType = "arraybuffer"; 

				// Store arraybuffers in audioBufferMap
				req.onload = function(){ 

					that.audioBufferMap[item] = req.response;

					ctr++;

					// Update progress
					progressCallback({
						total: that.audioFilenameList.length,
						current: ctr
					});

					// Resolve when all files are loaded
					if(ctr == that.audioFilenameList.length){
						resolve();
					}

				}; 

				req.send();

			});
	
		});
		
	};

	async decodeAudioFiles(){

		const that = this;

		// Skip if audioContext isn't setup
		if(this.audioContext.state === "suspended"){
			return;
		}

		return new Promise(function(resolve, reject){

			let ctr = 0;

			that.audioFilenameList.forEach(function(item){

				//decode the loaded data 
				that.audioContext.decodeAudioData(
					that.audioBufferMap[item],
					function(buffer){ 

						// Replace arraybuffer with decoded audio buffer
						that.audioBufferMap[item] = buffer;

						console.log(`${item} loaded successfully`);
						ctr++;
						if(ctr == that.audioFilenameList.length){
							resolve();
						}
					},
					function(error){
						reject();
					}

				); 

			});
	
		});
		
	};

	async resumeAudioContext(){

		const that = this;

		return new Promise(function(resolve, reject){

			that.audioContext.resume().then(
				function(){
					resolve(true);
				},
				function(reason){
					reject(reason);
				}
			);

		});

	};

	suspendAudioContext(e){

		const that = this;

		that.audioContext.suspend().then(
			function(){
				callback();
			},
			function(reason){
				console.log("rejected");
				console.log(reason);
			}
		);

	};

	play(audioBufferName){

		// Skip if audioContext isn't setup
		if(!this.audioContext){
			return;
		}

		// Skip if state is suspended
		if(this.audioContext.state === "suspended"){
			return;
		}

		// Create a source node from the buffer 
		const src = this.audioContext.createBufferSource();  

		src.buffer = this.audioBufferMap[audioBufferName]; 

		// Connect to the final output node (the speakers) 
		src.connect(this.audioContext.destination); 

		// Play
		src.start(0); 

	};

	playBgm(audioBufferName){

		// Skip if audioContext isn't setup
		if(!this.audioContext){
			return;
		}

		// Skip if state is suspended
		if(this.audioContext.state === "suspended"){
			return;
		}

		// Skip if no buffer
		if(!this.audioBufferMap[audioBufferName]){
			return;
		}

		// Stop BGM if already playing
		if(this.bgmSrc){
			this.bgmSrc.stop();
		}

		// Create a source node from the buffer 
		this.bgmSrc = this.audioContext.createBufferSource();  

		this.bgmSrc.buffer = this.audioBufferMap[audioBufferName]; 

		// Connect to the final output node (the speakers) 
		this.bgmSrc.connect(this.audioContext.destination); 

		// Play
		this.bgmSrc.loop = true;
		this.bgmSrc.start(0); 

		console.log("playing bgm");
		console.log(this.bgmSrc);
	}

	stopBgm(){

		// Skip if audioContext isn't setup
		if(!this.audioContext){return;}

		// Skip if state is suspended
		if(this.audioContext.state === "suspended"){return;}

		// Stop BGM if already playing
		if(this.bgmSrc){
			this.bgmSrc.stop();
			this.bgmSrc = null;
		}

	}


};

module.exports = JuneAudioController;


//====================================================================================================
// EOF
//====================================================================================================