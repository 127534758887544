//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// June Modules
import  JuneGameObject from "./JuneGameObject.js";
import  JuneColliderComponent from "./JuneColliderComponent.js";
import  JuneSceneController from "./JuneSceneController.js";
import  JuneSpriteComponent from "./JuneSpriteComponent.js";
import  JuneSpriteController from "./JuneSpriteController.js";
import  Button from "./Button.js";

// App
import  constants from "./common/constants.js";
import  helper from "./helper.js";

import  PlayerComponent from "./PlayerComponent.js";
import  BlockManager from "./BlockManager.js";

// Modes
import  ModeBubbleRace from "./ModeBubbleRace.js";
import  ModeZoneOut from "./ModeZoneOut.js";
import  ModeProgression from "./ModeProgression.js";
import  MoveToPoint from "./MoveToPoint.js";
import  EnemyComponent from "./EnemyComponent.js";
import  BubbleComponent from "./BubbleComponent.js";
import  ParticleComponent from "./ParticleComponent.js";
import  StarComponent from "./StarComponent.js";
import  StarItemComponent from "./StarItemComponent.js";
import  FloatComponent from "./FloatComponent.js";
import  JuneTextComponent from "./JuneTextComponent.js";
import  EnemyFlyerComponent from "./EnemyFlyerComponent.js";
import  EnemyMiirComponent from "./EnemyMiirComponent.js";
import  DamageTakerComponent from "./DamageTakerComponent.js";
import  CrawlerComponent from "./CrawlerComponent.js";
import  DottedLineComponent from "./DottedLineComponent.js";


//====================================================================================================
// GameSceneController
//====================================================================================================



class GameSceneController extends JuneSceneController{

	constructor(app){

		super(app);

		// Edit mode
		this.editMode = false;
		// this.editMode = true;

		// Override default app settings
		this.app.renderer.setClearColor(constants.COLOR_DARK_BLUE_SKY);
		this.camera.updateProjectionMatrix();

		// Movement
		this.slidePosition = 0.0;

		// Collision
		this.raycaster = new three.Raycaster();
		this.raycaster.layers.set(constants.LAYER_COLLISION);

		// State
		this.GAME_OVER_PAUSE_DURATION = 3.0;
		this.GAME_STATE_NORMAL = 0;
		this.GAME_STATE_GAME_OVER_PAUSE = 1;
		this.state = this.GAME_STATE_NORMAL;

		// Bind functions
		this.initialize = this.initialize.bind(this);
		this.onMouseDown = this.onMouseDown.bind(this);

	};


	//====================================================================================================
	// Initialization
	//====================================================================================================


	async initialize(){

		let that = this;

		// Load textures
		this.spriteController = new JuneSpriteController({
			sceneController: this,
			spritePoolSize: 1024
		});
		this.scene.add(this.spriteController.masterMesh);

		// Bubbles
		this.bubblePoolSize = 32;
		this.bubblePool = [];
		this.firstAvailableBubble = null;
		this.createBubblePool();

		// Particles
		this.particlePoolSize = 256;
		this.particlePool = [];
		this.firstAvailableParticle = null;
		this.createParticlePool();

		// Get day data
		this.dateData = {};
		this.mapData = {};
		// HACK in date for now (year, month, day order)
		this.activeDate = new Date(2020, 9, 11);
		await this.cacheDataForDate(this.activeDate);

		// Star field
		this.createStarField();

		// Blocks
		this.blockManager = new BlockManager(this);

		// Bubble Gum Dream Machine
		this.bgdm = new JuneGameObject("bgdm", this);
		this.bgdm.addComponent(
			new PlayerComponent({
				gameObject: this.bgdm,
			})
		);
		this.bgdm.addComponent(
			new JuneSpriteComponent({
				gameObject: this.bgdm,
				dynamic: true,
				size: 64,
				texture: "dream_machine.png"
			})
		);
		this.bgdm.addComponent(
			new JuneColliderComponent({
				gameObject: this.bgdm,
				size: 64
			})
		);
		this.bgdm.addComponent(
			new DamageTakerComponent({
				gameObject: this.bgdm,
				hp: 3.0,
				invincibleDuration: 1.0,
				normalTexture: "dream_machine.png",
				flashTexture: "dream_machine_flash.png",
				onDamageCallback: this.bgdm.components.PlayerComponent.onDamage,
				onDefeatFunc: this.bgdm.components.PlayerComponent.onDefeat
			})
		);
		this.bgdm.addComponent(
			new MoveToPoint({
				gameObject: this.bgdm,
				speed: 3.0,
				stopThreshold: 10.0,
				ease: true
			})
		);
		this.bgdm.addComponent(
			new DottedLineComponent({
				gameObject: this.bgdm,
				cursorTexture: "cursor.png",
				dotTexture: "dot.png"
			})
		);
		this.bgdm.setPosition({
			x: 0.0,
			y: 0.0,
			z: constants.Z_FRONT
		});
		this.addGameObject(this.bgdm);

		// Top machine
		this.topMachine = new JuneGameObject("top-machine", this);
		this.topMachine.addComponent(
			new JuneSpriteComponent({
				gameObject: this.topMachine,
				size: 1024,
				texture: "top_machine.png"
			})
		);
		this.topMachine.setPosition({
			x: 0.0,
			y: (this.app.gameHeight / 2) - 512,
			z: 0.0
		});
		this.addGameObject(this.topMachine);


		// UI sprite
		this.case = new JuneGameObject("case", this);
		this.case.addComponent(
			new JuneSpriteComponent({
				gameObject: this.case,
				size: 1024,
				texture: "buttons.png"
			})
		);
		this.case.setPosition({
			x: 0.0,
			// y: -(this.app.gameHeight / 2) + (1024 / 2),
			y: -(this.app.gameHeight / 2) + (1024 / 2) - 140,
			z: constants.Z_UI
		});
		this.addGameObject(this.case);

		// Slide button mesh
		const buttonWidth = this.app.gameWidth * 0.5;
		const buttonHeight = buttonWidth * 0.5;
		const slideButtonWidth = this.app.gameWidth * 0.5;
		const slideButtonHeight = this.app.gameWidth * 0.5;

		// Slide button logic
		let slideButton = new Button({
			position: new three.Vector3(
				-this.app.gameWidth * 0.25, 
				(this.app.gameHeight * -0.5) + (slideButtonHeight * 0.5),
				constants.Z_UI
			),
			collisionSize: new three.Vector2(
				this.app.gameWidth * 0.5,
				this.app.gameWidth * 0.5,
			),
			onHoverStartFunc: function(e){
			},
			onHoverEndFunc: function(){
			},
			onPressFunc: function(e){
				that.bgdm.components.PlayerComponent.onDirectionSlide(e);
			},
			onDownFunc: function(e){
				that.bgdm.components.PlayerComponent.onDirectionSlide(e);
			},
			onReleaseFunc: function(){
			},
			onCancelFunc: function(){
			},
			z: 1.0
		});
		this.buttons.push(slideButton);

		// jump button logic
		let jumpButton = new Button({
			position: new three.Vector3(
				this.app.gameWidth * 0.25,
				(this.app.gameHeight * -0.5) + (buttonHeight * 0.5),
				constants.Z_UI
			),
			collisionSize: new three.Vector2(
				buttonWidth,
				buttonHeight
			),
			onHoverStartFunc: function(){
			},
			onHoverEndFunc: function(){
			},
			onPressFunc: function(){
				that.bgdm.components.PlayerComponent.jump();
			},
			onDownFunc: function(){
			},
			onReleaseFunc: function(){
			},
			onCancelFunc: function(){
			},
			z: 1.0
		});
		this.buttons.push(jumpButton);

		// Grape button logic
		let grapeButton = new Button({
			position: new three.Vector3(
				this.app.gameWidth * 0.25,
				(this.app.gameHeight * -0.5) + (buttonHeight * 0.5) + buttonHeight,
				constants.Z_UI
			),
			collisionSize: new three.Vector2(
				buttonWidth,
				buttonHeight
			),
			onHoverStartFunc: function(){
			},
			onHoverEndFunc: function(){
			},
			onPressFunc: function(){
			},
			onDownFunc: function(){
				that.bgdm.components.PlayerComponent.shoot();
			},
			onReleaseFunc: function(){
			},
			onCancelFunc: function(){
			},
			z: 1.0
		});
		this.buttons.push(grapeButton);

		// Text
		const yOrigin = 5;
		const textSize = 64;
		this.screenText = new JuneGameObject(helper.uuidgen(), this);
		this.screenText.addComponent(
			new JuneTextComponent({
				gameObject: this.screenText,
				text: "-------",
				size: textSize
			})
		);
		this.screenText.setPosition({
			x: 0.0,
			y: (this.app.gameHeight * 0.5) - 64,
			z: 0.0
		});
		this.screenText.components.JuneTextComponent.setColor(new three.Color(0xcc3399));
		this.addGameObject(this.screenText);

		// Scan lines
		this.scanLines = new JuneGameObject(helper.uuidgen(), this);
		this.scanLines.addComponent(
			new JuneSpriteComponent({
				gameObject: this.scanLines,
				texture: "scan_lines.png",
				size: 512
			})
		);
		this.scanLines.setPosition({
			x: 0.0,
			y: (this.app.gameHeight * 0.5) - 70,
			z: 0.0
		})
		this.addGameObject(this.scanLines);

		// Screen icons
		const iconOffsetX = 88;
		const iconOffsetY = 67.5;
		this.leftIcon = new JuneGameObject(helper.uuidgen(), this);
		this.leftIcon.addComponent(
			new JuneSpriteComponent({
				gameObject: this.leftIcon,
				texture: "hearts_3.png",
				size: 64
			})
		);
		this.leftIcon.setPosition({
			x: (this.app.gameWidth * -0.5) + iconOffsetX,
			y: (this.app.gameHeight * 0.5) - iconOffsetY,
			z: 0.0
		});
		this.addGameObject(this.leftIcon);

		this.rightIcon = new JuneGameObject(helper.uuidgen(), this);
		this.rightIcon.addComponent(
			new JuneSpriteComponent({
				gameObject: this.rightIcon,
				texture: "shooting_star.png",
				size: 64
			})
		);
		this.rightIcon.setPosition({
			x: (this.app.gameWidth * 0.5) - iconOffsetX,
			y: (this.app.gameHeight * 0.5) - iconOffsetY,
			z: 0.0
		});
		this.addGameObject(this.rightIcon);


		// Keyboard controls
		this.keyInputs["Space"] = {
			onDownFunc: function(){
				that.bgdm.components.PlayerComponent.shoot();
			} 
		};
		this.keyInputs["ArrowUp"] = {
			onDownFunc: function(){
				that.bgdm.components.PlayerComponent.jump();
			} 
		};
		this.keyInputs["ArrowLeft"] = {
			onDownFunc: function(shiftKey){

				if(shiftKey){
					that.bgdm.components.PlayerComponent.onKeyboardRotate(-0.25);
				}else{
					that.bgdm.components.PlayerComponent.onKeyboardRotate(-0.5);
				}

			} 
		};
		this.keyInputs["ArrowRight"] = {
			onDownFunc: function(shiftKey){

				if(shiftKey){
					that.bgdm.components.PlayerComponent.onKeyboardRotate(0.25);
				}else{
					that.bgdm.components.PlayerComponent.onKeyboardRotate(0.5);
				}

			} 
		};

		this.activateDate(this.activeDate);

		// At beginning, put player in the landed state
		this.bgdm.components.PlayerComponent.land();

	}

	//====================================================================================================
	// Enemies
	//====================================================================================================


	makeEnemy({type}){

		const bubbleSpeed = 60.0;

		let enemy = new JuneGameObject(`enemy-${helper.uuidgen()}`, this);
		enemy.addComponent(
			new EnemyComponent({
				gameObject: enemy,
				defeatSfx: "bubble_collect.wav"
			})
		);
		enemy.addComponent(
			new JuneSpriteComponent({
				gameObject: enemy,
				size: 64,
				texture: "green_snail.png"
			})
		);
		enemy.addComponent(
			new JuneColliderComponent({
				gameObject: enemy,
				size: 64
			})
		);
		enemy.addComponent(
			new DamageTakerComponent({
				gameObject: enemy,
				hp: 5.0,
				invincibleDuration: 0.1,
				normalTexture: "green_snail.png",
				flashTexture: "green_snail_flash.png",
				onDefeatFunc: enemy.components.EnemyComponent.onDefeat
			})
		);

		if(type == "green_bubble"){
			enemy.components.EnemyComponent.color = "green";
			enemy.components.JuneSpriteComponent.updateUvsForTextureKey("green_bubble.png");
			enemy.components.DamageTakerComponent.hp = 1.0;
			enemy.addComponent(
				new FloatComponent({
					gameObject: enemy
				})
			);
			enemy.components.JuneColliderComponent.velocity.set(
				helper.randomRange(-bubbleSpeed, bubbleSpeed),
				helper.randomRange(-bubbleSpeed, bubbleSpeed),
				0.0
			);
		}
		if(type == "flyer"){
			enemy.components.EnemyComponent.color = "green";
			enemy.components.JuneSpriteComponent.updateUvsForTextureKey("flyer.png");
			enemy.components.JuneSpriteComponent.dynamic = true;
			enemy.components.DamageTakerComponent.hp = 20.0;
			enemy.components.DamageTakerComponent.normalTexture = "flyer.png",
			enemy.components.DamageTakerComponent.flashTexture = "flyer_flash.png",
			enemy.addComponent(
				new MoveToPoint({
					gameObject: enemy,
					speed: 3.0,
					stopThreshold: 10.0,
					ease: true
				})
			);
			enemy.addComponent(
				new EnemyFlyerComponent({
					gameObject: enemy,
				})
			);
			enemy.addComponent(
				new DottedLineComponent({
					gameObject: enemy,
					cursorTexture: "cursor_green.png",
					dotTexture: "dot_green.png"
				})
			);
		}
		if(type == "blue_crystaline"){
			enemy.components.EnemyComponent.color = "blue";
			enemy.components.EnemyComponent.defeatSfx = "shatter.wav";
			enemy.components.JuneSpriteComponent.updateUvsForTextureKey("blue_crystaline.png");
			enemy.components.DamageTakerComponent.hp = 3.0;
			enemy.components.DamageTakerComponent.normalTexture = "blue_crystaline.png";
			enemy.components.DamageTakerComponent.flashTexture = "blue_crystaline_flash.png";
		}
		if(type == "miir"){
			enemy.components.EnemyComponent.color = "blue";
			enemy.components.EnemyComponent.defeatSfx = "shatter.wav";
			enemy.components.JuneSpriteComponent.updateUvsForTextureKey("miir.png");
			enemy.components.JuneSpriteComponent.dynamic = true;
			enemy.components.DamageTakerComponent.hp = 5.0;
			enemy.components.DamageTakerComponent.normalTexture = "miir.png",
			enemy.components.DamageTakerComponent.flashTexture = "miir_flash.png",
			enemy.addComponent(
				new MoveToPoint({
					gameObject: enemy,
					speed: 3.0,
					stopThreshold: 10.0,
					ease: true
				})
			);
			enemy.addComponent(
				new EnemyMiirComponent({
					gameObject: enemy,
				})
			);
			enemy.addComponent(
				new DottedLineComponent({
					gameObject: enemy,
					cursorTexture: "cursor_blue.png",
					dotTexture: "dot_blue.png"
				})
			);
		}
		if(type == "crawler"){
			enemy.components.EnemyComponent.color = "green";
			enemy.components.JuneSpriteComponent.updateUvsForTextureKey("green_snail.png");
			enemy.components.JuneSpriteComponent.dynamic = true;
			enemy.addComponent(
				new MoveToPoint({
					gameObject: enemy,
					speed: 60.0,
					stopThreshold: 2.0,
					ease: false
				})
			);
			enemy.addComponent(
				new CrawlerComponent({
					gameObject: enemy,
					releaseBubbleFrequency: 4
				})
			);
		}

		this.addGameObject(enemy);

		return enemy;

	};

	makeStarItem({column, row}){

		const bubbleSpeed = 60.0;

		let starItem = new JuneGameObject(`starItem-${helper.uuidgen()}`, this);
		starItem.addComponent(
			new JuneSpriteComponent({
				gameObject: starItem,
				size: 64,
				texture: "star_bubble.png"
			})
		);
		starItem.addComponent(
			new JuneColliderComponent({
				gameObject: starItem,
				size: 64
			})
		);
		starItem.addComponent(
			new FloatComponent({
				gameObject: starItem
			})
		);
		starItem.addComponent(
			new StarItemComponent({
				gameObject: starItem
			})
		);
		starItem.components.JuneColliderComponent.velocity.set(
			helper.randomRange(-bubbleSpeed, bubbleSpeed),
			helper.randomRange(-bubbleSpeed, bubbleSpeed),
			0.0
		);

		starItem.setPosition(this.blockManager.getPositionFromGridCoordinates({column: column, row: row}));

		this.addGameObject(starItem);

	};
	

	//====================================================================================================
	// STAR FIELD
	//====================================================================================================


	createStarField(){

		const NUM_STARS = 64;

		for(let i = 0; i < NUM_STARS; i++){

			const star = new JuneGameObject(`star-${helper.uuidgen()}`, this);

			// Random texture
			const starData = helper.randomFromArray([
				{texture: "star_1.png", speed: 1.5},
				{texture: "star_1.png", speed: 1.5},
				{texture: "star_2.png", speed: 3.0},
				{texture: "star_3.png", speed: 3.6}
			]);
	
			star.addComponent(
				new JuneSpriteComponent({
					gameObject: star,
					size: 16,
					texture: starData.texture
				})
			);
			star.addComponent(
				new StarComponent({
					gameObject: star,
					velocity: new three.Vector3(starData.speed, -starData.speed, 0.0)
				})
			);

			// Random position
			star.setPosition({
				x: helper.randomRange(-this.app.gameWidth * 0.5, this.app.gameWidth * 0.5),
				y: helper.randomRange(-this.app.gameHeight * 0.5, this.app.gameHeight* 0.5),
				z: -1.0
			});
			this.addGameObject(star);

		}

	}


	//====================================================================================================
	// CALENDAR
	//====================================================================================================


	// Dates are in Year, Month, Day order
	getStringFromDate(date){
		
		const dd = date.getDate();
		const mm = date.getMonth() + 1;
		const yyyy = date.getFullYear();
		return `${yyyy}_${mm}_${dd}`;

	};

	async cacheDataForDate(date){

		const dateString = this.getStringFromDate(date);

		const response = await fetch(`/day_data/${dateString}.json`);
		this.dateData[dateString] = await response.json();

		// Cache map data if not already available
		if(!this.mapData[this.dateData[dateString].mapName]){
			await this.cacheMapData(this.dateData[dateString].mapName);
		}

	};

	activateDate(date){

		const dateString = this.getStringFromDate(date);
		const dateData = this.dateData[dateString];

		// Populate blocks
		this.blockManager.loadBlocks(
			this.mapData[dateData.mapName].blockData
		);

		// Load mode
		if(dateData.mode == "bubble_race"){
			this.activeMode = new ModeBubbleRace(this);
			this.activeMode.initialize(dateData.bubbleData, dateData.enemyData);
		}
		if(dateData.mode == "zone_out"){
			this.activeMode = new ModeZoneOut(this);
			this.activeMode.initialize();
		}
		if(dateData.mode == "progression"){
			this.activeMode = new ModeProgression(this);
			this.activeMode.initialize(this.mapData[dateData.mapName].waves);
		}

		// Start position
		const startCoords = this.mapData[dateData.mapName].startPosition;
		const startPosition = this.blockManager.getPositionFromGridCoordinates({column: startCoords.c, row: startCoords.r});
		this.bgdm.setPosition({
			x: startPosition.x,
			y: startPosition.y,
			z: startPosition.z
		});

	};

	async cacheMapData(mapName){

		const response = await fetch(`/map_data/${mapName}.json`);
		this.mapData[mapName] = await response.json();

	}


	//====================================================================================================
	// BUBBLES
	//====================================================================================================


	createBubblePool(){

		for(let i = 0; i < this.bubblePoolSize; i++){

			const bubble = this.makeBubble();
	
			this.bubblePool.push(bubble);

		}

		// Set next
		for(let i = 0; i < this.bubblePoolSize - 1; i++){
			this.bubblePool[i].components.BubbleComponent.next = this.bubblePool[i+1];
		}
		this.bubblePool[this.bubblePoolSize - 1].components.BubbleComponent.next = null;
		
		// Set firstAvailable
		this.firstAvailableBubble = this.bubblePool[0];
	};

	getFreeBubble(){

		if(this.firstAvailableBubble === null){
			return null;
		}

		const returnBubble = this.firstAvailableBubble;
		this.firstAvailableBubble = returnBubble.components.BubbleComponent.next;

		return returnBubble;

	};

	setFreeBubble(bubble){
		bubble.components.BubbleComponent.next = this.firstAvailableBubble;
		this.firstAvailableBubble = bubble;
	};

	makeBubble(){
		
		const bubbleSpeed = 45.0;

		const bubble = new JuneGameObject(`bubble-${helper.uuidgen()}`, this);
		bubble.addComponent(
			new JuneSpriteComponent({
				gameObject: bubble,
				size: 64,
				texture: "bubble_small.png"
			})
		);
		bubble.addComponent(
			new BubbleComponent({
				gameObject: bubble
			})
		);
		bubble.addComponent(
			new FloatComponent({
				gameObject: bubble
			})
		);
		bubble.addComponent(
			new JuneColliderComponent({
				gameObject: bubble,
				size: 64
			})
		);
		bubble.components.JuneColliderComponent.velocity.set(
			helper.randomRange(-bubbleSpeed, bubbleSpeed),
			helper.randomRange(-bubbleSpeed, bubbleSpeed),
			0.0
		);

		this.addGameObject(bubble);

		return bubble;

	}


	//====================================================================================================
	// PARTICLES
	//====================================================================================================


	createParticlePool(){

		for(let i = 0; i < this.particlePoolSize; i++){

			const particle = this.makeParticle();
	
			this.particlePool.push(particle);

		}

		// Set next
		for(let i = 0; i < this.particlePoolSize - 1; i++){
			this.particlePool[i].components.ParticleComponent.next = this.particlePool[i+1];
		}
		this.particlePool[this.particlePoolSize - 1].components.ParticleComponent.next = null;
		
		// Set firstAvailable
		this.firstAvailableParticle = this.particlePool[0];
	};

	getFreeParticle(){

		if(this.firstAvailableParticle === null){
			return null;
			// throw(new Error("Out of particles"));
		}

		const returnParticle = this.firstAvailableParticle;
		this.firstAvailableParticle = returnParticle.components.ParticleComponent.next;

		return returnParticle;

	};

	setFreeParticle(particle){
		particle.components.ParticleComponent.next = this.firstAvailableParticle;
		this.firstAvailableParticle = particle;
	};

	makeParticle(){
		
		const particle = new JuneGameObject(`particle-${helper.uuidgen()}`, this);
		particle.addComponent(
			new JuneSpriteComponent({
				gameObject: particle,
				size: 8,
				texture: "particle_pink.png"
			})
		);
		particle.addComponent(
			new ParticleComponent({
				gameObject: particle
			})
		)

		this.addGameObject(particle);

		return particle;

	}


	//====================================================================================================
	// UI
	//====================================================================================================


	onMouseMove(e){

		if(this.editMode){
			this.handleEditMode();
		}

	}

	onMouseDown(e){

		const that = this;

		if(this.editMode){
			this.handleEditMode(e);
		}
		
	}

	handleEditMode(e){

		const coords = this.blockManager.getCoordinatesFromPosition(e);

		if(!coords){return;}

		if(window.editModeState == constants.EDIT_MODE_DRAW){
			this.blockManager.makeBlock(coords.column, coords.row, window.editModeBlockType, window.editModeDirection);
		}else if(window.editModeState == constants.EDIT_MODE_DELETE){
			this.blockManager.deleteBlock(coords.column, coords.row);
		}

	}


	//====================================================================================================
	// STATE
	//====================================================================================================


	cleanUp(){

		// Clean up
		this.blockManager.clearBlocks();
		this.bgdm.components.PlayerComponent.restart();
		
		// Clear bubbles
		const bubbles = Object.values(this.gameObjects).filter(function(x){
			return x.name.includes("bubble-")
		});
		bubbles.forEach(function(x){
			x.components.BubbleComponent.deactivate();
		});

		// Clear enemies
		const enemies = Object.values(this.gameObjects).filter(function(x){
			return x.components.EnemyComponent;
		});
		enemies.forEach(function(x){
			x.onDelete();
		});

	};

	restartGame(){

		this.cleanUp();

		// Re-activate date
		this.activateDate(this.activeDate);

		// At beginning, put player in the landed state
		this.bgdm.components.PlayerComponent.land();

		// Refill player hp
		this.bgdm.components.DamageTakerComponent.hp = 3;
		this.leftIcon.components.JuneSpriteComponent.updateUvsForTextureKey("hearts_3.png");

	}

	startGameOver(){

		this.state = this.GAME_STATE_GAME_OVER_PAUSE;
		this.stateTimer = this.GAME_OVER_PAUSE_DURATION;
		this.screenText.components.JuneTextComponent.updateText("GAMEOVR");

	}


	//====================================================================================================
	// Update loop
	//====================================================================================================


	update(){

		// Update based on game state
		switch(this.state){

			case this.GAME_STATE_GAME_OVER_PAUSE:
				this.stateTimer -= this.app.dt;
				if(this.stateTimer <= 0){
					this.state = this.GAME_STATE_NORMAL;
					this.app.gotoResultFromGame({won: false});
				}
				break;

			case this.GAME_STATE_NORMAL:

				// Super update
				super.update();

				// Mode update
				if(this.activeMode){
					this.activeMode.update();
				}

				break;
		}

	};

};

module.exports = GameSceneController;


//====================================================================================================
// EOF
//====================================================================================================
