//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// WinningModules

// App

// Factories


//====================================================================================================
// JuneSceneController
//====================================================================================================


class JuneSceneController{

	constructor(app){

		this.app = app;

		// GameObjects
		this.gameObjectsToAdd = {};
		this.gameObjects = {};

		// Buttons
		this.buttons = [];

		// Key inputs
		this.keyInputs = {};

		// Camera
		// scene > cameraSwivelObjectBase > cameraSwivelObjectY > cameraSwivelObjectX > camera
		this.cameraSwivelObjectBase = new three.Object3D();
		this.cameraSwivelObjectY = new three.Object3D();
		this.cameraSwivelObjectX = new three.Object3D();
		this.camera = new three.OrthographicCamera(
			-(this.app.gameWidth / 2),
			(this.app.gameWidth / 2),
			(this.app.gameHeight / 2),
			-(this.app.gameHeight / 2),
			0.0,
			10000.0
		);
		this.camera.position.z = 5000.0;
		this.camera.zoom = 1.0;
		this.cameraSwivelObjectX.add(this.camera);
		this.cameraSwivelObjectY.add(this.cameraSwivelObjectX);
		this.cameraSwivelObjectBase.add(this.cameraSwivelObjectY);
		this.camera.updateProjectionMatrix();

		// Scene
		this.scene = new three.Scene();
		this.scene.add(this.cameraSwivelObjectBase);

	};

	addGameObject(gameObject){
		this.scene.add(gameObject.object3D);
		this.gameObjectsToAdd[gameObject.name] = gameObject;
	};

	removeGameObject(gameObject){
		this.scene.remove(gameObject.object3D);
		delete this.gameObjects[gameObject.name];
	};


	//====================================================================================================
	// Update loop
	//====================================================================================================


	update(){

		const that = this;

		// Update all active GameObjects
		Object.values(this.gameObjects).forEach(function(go){

			// Update active objects
			if(go.active){
				go.gameObjectUpdate();
			}

			if(!go.deleteMe){
				that.gameObjectsToAdd[go.name] = go;
			}

		});

		// Only keep relevant GameObjects
		this.gameObjects = {};
		Object.assign(
			this.gameObjects,
			this.gameObjectsToAdd,
		);
		this.gameObjectsToAdd = {};

	};

};

module.exports = JuneSceneController;


//====================================================================================================
// EOF
//====================================================================================================