//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// June
import JuneComponent from "./JuneComponent.js";

// App
import constants from "./common/constants.js";
import helper from "./helper.js";


//====================================================================================================
// BlockComponent
//====================================================================================================


class BlockComponent extends JuneComponent{

	constructor(params){

		super(params);
		
		this.column = params.column;
		this.row = params.row;
		this.type = params.type

	};

};

module.exports = BlockComponent;


//====================================================================================================
// EOF
//====================================================================================================