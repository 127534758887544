//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// WinningModules

// App
import  constants from "./common/constants.js";


//====================================================================================================
// JuneGameObject
//====================================================================================================

// Components most JGOs will have:
// rigidBody
// sprite

// Pooling
// Expect JGOs to be in a pool
// - Need "next" pointers
// - Need cleanup functions
//   - 

class JuneGameObject {

	constructor(name, sceneController){

		this.name = name;

		this.sceneController = sceneController;

		this.active = true;

		this.deleteMe = false;

		this.visible = true;

		this.app = sceneController.app;

		// Object3D is used in place of Unity transform.
		// Ideally user code doesn't have to know much about this object
		this.object3D = new three.Object3D();
		this.object3D.userData.gameObject = this;

		this.components = {};

		this.gameObjectUpdate = this.gameObjectUpdate.bind(this);

	};

	setPosition({x, y, z}){

		this.object3D.position.set(x, y, z);

		this.callComponentsWithFuncName("onUpdatePosition");

	};

	setRotation({x, y, z}){

		this.object3D.rotation.set(x, y, z);

		this.callComponentsWithFuncName("onUpdateRotation");

	};

	setVisible(visible){
		this.visible = visible;
		this.callComponentsWithFuncName("onUpdateVisibility");	
	};

	onDelete(){

		// Skip if already scheduled for deletion
		if(this.deleteMe){return;}

		// Clean up components
		this.callComponentsWithFuncName("onDelete");
		this.components = {};

		// Remove from scene
		this.deleteMe = true;
		this.sceneController.removeGameObject(this);

	}

	onTriggerEnter2D({gameObject, intersectionObject}){
		this.callComponentsWithFuncName(
			"onTriggerEnter2D",
			{
				gameObject: gameObject,
				intersectionObject: intersectionObject
			}
		);
	};

	callComponentsWithFuncName(funcName, params){

		Object.values(this.components).forEach(function(c){
			if(c[funcName]){
				c[funcName](params);
			}
		});

	};

	addComponent(component){
		this.components[component.getName()] = component;
	};

	removeComponent(component){
		delete this.components[component.getName()];
	};

	deactivate(){
	}

	gameObjectUpdate(){

		Object.values(this.components).forEach(function(c){

			if(!c.enabled){return;}

			c.update();

		});

	};

};

module.exports = JuneGameObject;


//====================================================================================================
// EOF
//====================================================================================================