//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// June Modules
import  JuneGameObject from "./JuneGameObject.js";
import  JuneColliderComponent from "./JuneColliderComponent.js";
import  JuneSceneController from "./JuneSceneController.js";
import  JuneSpriteComponent from "./JuneSpriteComponent.js";
import  JuneSpriteController from "./JuneSpriteController.js";
import  Button from "./Button.js";

// App
import  constants from "./common/constants.js";
import  helper from "./helper.js";

import  PlayerComponent from "./PlayerComponent.js";
import  BlockManager from "./BlockManager.js";

// Modes
import  ModeBubbleRace from "./ModeBubbleRace.js";
import  ModeZoneOut from "./ModeZoneOut.js";
import  ModeProgression from "./ModeProgression.js";
import  MoveToPoint from "./MoveToPoint.js";
import  EnemyComponent from "./EnemyComponent.js";
import  BubbleComponent from "./BubbleComponent.js";
import  ParticleComponent from "./ParticleComponent.js";
import  StarComponent from "./StarComponent.js";
import  StarItemComponent from "./StarItemComponent.js";
import  FloatComponent from "./FloatComponent.js";
import  JuneTextComponent from "./JuneTextComponent.js";
import  EnemyFlyerComponent from "./EnemyFlyerComponent.js";
import  EnemyMiirComponent from "./EnemyMiirComponent.js";
import  DamageTakerComponent from "./DamageTakerComponent.js";
import  CrawlerComponent from "./CrawlerComponent.js";
import  DottedLineComponent from "./DottedLineComponent.js";


//====================================================================================================
// ResultSceneController
//====================================================================================================



class ResultSceneController extends JuneSceneController{

	constructor(app){

		super(app);

		// Edit mode
		// this.editMode = false;
		this.editMode = true;

		// Override default app settings
		this.app.renderer.setClearColor(constants.COLOR_DARK_BLUE_SKY);
		this.camera.updateProjectionMatrix();

		// Collision
		this.raycaster = new three.Raycaster();
		this.raycaster.layers.set(constants.LAYER_COLLISION);

		// State
		this.state = this.GAME_STATE_NORMAL;

		// Bind functions
		this.initialize = this.initialize.bind(this);
		this.makeMenuButton = this.makeMenuButton.bind(this);

	};


	//====================================================================================================
	// Initialization
	//====================================================================================================

	makeMenuButton({text, position, onPressFunc}){

		const buttonHeight = 64;
		const fontSize = 64;

		// Text sprite
		const textGameObject = new JuneGameObject(helper.uuidgen(), this);
		textGameObject.addComponent(
			new JuneTextComponent({
				gameObject: textGameObject,
				text: text,
				size: fontSize
			})
		);
		textGameObject.setPosition(position);
		this.addGameObject(textGameObject);

		// Button
		let button = new Button({
			position: position,
			collisionSize: new three.Vector2(
				this.app.gameWidth,
				buttonHeight
			),
			onHoverStartFunc: function(){
			},
			onHoverEndFunc: function(){
			},
			onPressFunc: function(){
				onPressFunc();
			},
			onDownFunc: function(){
			},
			onReleaseFunc: function(){
			},
			onCancelFunc: function(){
			},
			z: 1.0
		});
		this.buttons.push(button);

	};

	async initialize(){

		let that = this;

		// Load textures
		this.spriteController = new JuneSpriteController({
			sceneController: this,
			spritePoolSize: 32
		});
		this.scene.add(this.spriteController.masterMesh);
		
		// Logo sprite
		const bubbleText = new JuneGameObject(helper.uuidgen(), this);
		bubbleText.addComponent(
			new JuneTextComponent({
				gameObject: bubbleText,
				text: `${this.app.sceneControllers.gameSceneController.activeMode.numBubblesCollected} BUBBLES!`,
				size: 64
			})
		);
		bubbleText.setPosition({
			x: 0.0,
			y: 100.0,
			z: 0.0
		});
		this.addGameObject(bubbleText);

		this.makeMenuButton({
			text: "PLAY AGAIN!",
			position: {x: 0.0, y: 0.0, z: 0.0},
			onPressFunc: that.app.gotoGameFromResult
		});

	}
	
	//====================================================================================================
	// UI
	//====================================================================================================



	//====================================================================================================
	// STATE
	//====================================================================================================




	//====================================================================================================
	// Update loop
	//====================================================================================================


	update(){

		// Update based on game state
		switch(this.state){

		
		}

	};

};

module.exports = ResultSceneController;


//====================================================================================================
// EOF
//====================================================================================================