//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// WinningModules
import  JuneComponent from "./JuneComponent.js";

// App
import  constants from "./common/constants.js";
import  helper from "./helper.js";
import  MoveToPoint from "./MoveToPoint.js";


//====================================================================================================
// EnemyFlyerComponent
//====================================================================================================

const ENEMY_STATE_IDLE = 0;
const ENEMY_STATE_ROTATING = 1;
const ENEMY_STATE_PRE_JUMPING = 2;
const ENEMY_STATE_JUMPING = 3;

const IDLE_TIME = 1.0;
const PRE_JUMPING_TIME = 1.5;
const ROTATION_SPEED = 1.5;

class EnemyFlyerComponent extends JuneComponent{

	constructor(params){

		super(params);

		// State
		this.state = ENEMY_STATE_IDLE;
		this.targetRotation = 0.0;
		this.timer = IDLE_TIME;

		// Raycaster
		// Add raycaster to proper collision layer
		this.raycaster = new three.Raycaster();
		this.raycaster.layers.set(constants.LAYER_COLLISION);

		this.onLand = this.onLand.bind(this);

	};

	onLand(){
		this.state = ENEMY_STATE_IDLE;
		this.timer = IDLE_TIME;
	}

	checkForPlayer(){

		// Cast rays and draw dots until length is reached
		const angle = this.gameObject.object3D.rotation.z + 1.57;
		const direction = new three.Vector3(Math.cos(angle), Math.sin(angle), 0.0);

		this.raycaster.set(
			this.gameObject.object3D.position,
			direction
		);

		const intersections = this.raycaster.intersectObjects(this.gameObject.sceneController.scene.children, false);

		// Iterate
		while(intersections.length > 0){

			// Pop off first intersection object
			const io = intersections.splice(0, 1)[0];

			// Return early if block is in the way
			if(io.object.userData.gameObject.components.BlockComponent){
				return null;
			}

			// Return if PlayerComponent is found
			if(io.object.userData.gameObject.components.PlayerComponent){
				return io.object.userData.gameObject;
			}

		}

		return null;

	};

	update(){

		switch(this.state){
			case ENEMY_STATE_IDLE:
				this.timer -= this.app.dt;

				// Setup rotation
				if(this.timer <= 0){
					this.state = ENEMY_STATE_ROTATING;
				}
				break;

			case ENEMY_STATE_ROTATING:

				// Rotate
				this.gameObject.setRotation({
					x: 0.0,
					y: 0.0,
					z: this.gameObject.object3D.rotation.z - (ROTATION_SPEED * this.app.dt)
				});

				// Look for player
				const playerGameObject = this.checkForPlayer();
				
				if(playerGameObject){

					this.state = ENEMY_STATE_PRE_JUMPING;
					this.timer = PRE_JUMPING_TIME;

					this.gameObject.components.DottedLineComponent.drawDottedLine(
						this.gameObject.object3D.position,
						this.gameObject.object3D.rotation.z,
						2000.0,
						0,
						0
					);

				}
				break;

			case ENEMY_STATE_PRE_JUMPING:
				this.timer -= this.app.dt;

				// Setup rotation
				if(this.timer <= 0){

					this.gameObject.components.DottedLineComponent.clearAll();

					this.state = ENEMY_STATE_JUMPING;

					// Find target block
					const angle = this.gameObject.object3D.rotation.z + 1.57;
					const direction = new three.Vector3(Math.cos(angle), Math.sin(angle), 0.0);
					const intersection = this.gameObject.sceneController.blockManager.getBlockCollisionFromRay(
						this.gameObject.object3D.position,
						direction
					);

					const normal = intersection.face.normal;

					this.gameObject.components.MoveToPoint.destination = intersection.object.userData.gameObject.object3D.position.clone()
						.addScaledVector(normal, this.gameObject.sceneController.blockManager.BLOCK_SIZE);

					this.gameObject.components.MoveToPoint.reachedDestinationFunc = this.onLand;
				}
				break;

			case ENEMY_STATE_JUMPING:
				break;
		}

	}

};

module.exports = EnemyFlyerComponent;


//====================================================================================================
// EOF
//====================================================================================================