//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// WinningModules
import  JuneComponent from "./JuneComponent.js";

// App
import  constants from "./common/constants.js";
import  helper from "./helper.js";
import  MoveToPoint from "./MoveToPoint.js";


//====================================================================================================
// EnemyMiirComponent
//====================================================================================================

const ENEMY_STATE_IDLE = 0;
const ENEMY_STATE_ROTATING = 1;
const ENEMY_STATE_PRE_JUMPING = 2;
const ENEMY_STATE_JUMPING = 3;


class EnemyMiirComponent extends JuneComponent{

	constructor(params){

		super(params);

		// State
		this.state = ENEMY_STATE_IDLE;

		this.player = this.gameObject.sceneController.bgdm;

		// Raycaster
		// Add raycaster to proper collision layer
		this.raycaster = new three.Raycaster();
		this.raycaster.layers.set(constants.LAYER_COLLISION);

		this.onLand = this.onLand.bind(this);

	};

	onLand(){
		this.state = ENEMY_STATE_IDLE;
	}

	update(){

		switch(this.state){

			case ENEMY_STATE_IDLE:

				// Wait for player to land
				if(this.player.components.MoveToPoint.destination == null){
					this.state = ENEMY_STATE_ROTATING;
				}

				break;

			case ENEMY_STATE_ROTATING:

				// Rotate 180 degrees opposite of player rotation
				this.gameObject.setRotation({
					x: 0.0,
					y: 0.0,
					z: this.player.object3D.rotation.z - (Math.PI)
				});

				this.gameObject.components.DottedLineComponent.drawDottedLine(
					this.gameObject.object3D.position,
					this.gameObject.object3D.rotation.z,
					2000.0,
					0,
					0
				);

				// Check if player is jumping
				if(this.player.components.MoveToPoint.destination){
					this.state = ENEMY_STATE_PRE_JUMPING;
				}

				break;

			case ENEMY_STATE_PRE_JUMPING:

				this.gameObject.components.DottedLineComponent.clearAll();

				this.state = ENEMY_STATE_JUMPING;

				// Find target block
				const angle = this.gameObject.object3D.rotation.z + 1.57;
				const direction = new three.Vector3(Math.cos(angle), Math.sin(angle), 0.0);
				const intersection = this.gameObject.sceneController.blockManager.getBlockCollisionFromRay(
					this.gameObject.object3D.position,
					direction
				);

				const normal = intersection.face.normal;

				this.gameObject.components.MoveToPoint.destination = intersection.object.userData.gameObject.object3D.position.clone()
					.addScaledVector(normal, this.gameObject.sceneController.blockManager.BLOCK_SIZE);

				this.gameObject.components.MoveToPoint.reachedDestinationFunc = this.onLand;

				// Drop crystaline
				const blueCrystaline = this.gameObject.sceneController.activeMode.makeAdHocEnemy("blue_crystaline");
				blueCrystaline.setPosition(this.gameObject.object3D.position);

				break;

			case ENEMY_STATE_JUMPING:
				break;
		}

	}

};

module.exports = EnemyMiirComponent;


//====================================================================================================
// EOF
//====================================================================================================