//====================================================================================================
// Imports
//====================================================================================================


// Third-party
import * as three from "three";

// June Modules
import  JuneGameObject from "./JuneGameObject.js";
import  JuneSceneController from "./JuneSceneController.js";
import  JuneSpriteController from "./JuneSpriteController.js";

// App
import  constants from "./common/constants.js";
import  helper from "./helper.js";


//====================================================================================================
// ResultSceneController
//====================================================================================================



class LoadingSceneController extends JuneSceneController{

	constructor(app){

		super(app);

		// Override default app settings
		this.app.renderer.setClearColor(constants.COLOR_DARK_BLUE_SKY);
		this.camera.updateProjectionMatrix();

		// Bind functions
		this.initialize = this.initialize.bind(this);

	};


	//====================================================================================================
	// Initialization
	//====================================================================================================


	async initialize(){
		
		const that = this;

		const divPosition = this.app.worldToScreenCoordinates({
			x: -200,
			y: 0.0
		});
		this.textDiv = document.createElement("div");
		this.textDiv.style.position = "absolute";
		this.textDiv.style.fontSize = "24px";
		this.textDiv.style.color = "#ffffff";
		this.textDiv.style.left = divPosition.x + "px";
		this.textDiv.style.top = divPosition.y + "px";

		this.textDiv.innerText = "Loading...";
		document.body.append(this.textDiv);


	}


	//====================================================================================================
	// CLEAN UP
	//====================================================================================================


	cleanUp(){

		this.textDiv.remove();

	};
	
	//====================================================================================================
	// UI
	//====================================================================================================


	updateLoadingText(text){

		this.textDiv.innerText = text;

	};


	//====================================================================================================
	// STATE
	//====================================================================================================




	//====================================================================================================
	// Update loop
	//====================================================================================================


	update(){

		// Update based on game state
		switch(this.state){

		
		}

	};

};

module.exports = LoadingSceneController;


//====================================================================================================
// EOF
//====================================================================================================